import { FC, useEffect, useState } from 'react'

interface Props {
  toApp: (text: number) => void
  name: string
  label: string
  labelClassNames: string[]
  state: number
}

export const WidthHeight: FC<Props> = ({ toApp, name, label, labelClassNames, state }) => {
  const [text, setText] = useState(state)

  useEffect(() => {
    toApp(text)
  }, [text])

  return (
    <div className="col position-relative w-100 m-2" style={{ position: 'relative' }}>
      <label htmlFor={name} className={`label mt-2 ${labelClassNames}`}>{label}</label>
      <div className="mt-3">
        <input
          defaultValue={text}
          onChange={(e) => setText(+(e.target as HTMLInputElement).value)}
          type="number"
          name={name}
          id={name}
          className="d-block w-100 rounded border border-1 pt-3 px-2 py-2 text-dark text-center display-6"
        />
      </div>
    </div>
  )
}