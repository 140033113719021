export const gorizontLenta25: any = [
  ['0221', 14.12, 'белый глянцевый'],
  ['0120', 17.50, 'белый матовый'],
  ['1606', 17.50, 'светло-серый'],
  ['1852', 17.50, 'серый матовый'],
  ['1854', 17.50, 'графит'],
  ['1881', 17.50, 'тёмно-серый'],
  ['1908', 17.50, 'черый'],
  ['2259', 17.50, 'магнолия'],
  ['2261', 17.50, 'светло-бежевый'],
  ['2406', 17.50, 'темно-бежевый'],
  ['2746', 17.50, 'шоколад'],
  ['2871', 17.50, 'коричневый'],
  ['3144', 17.50, 'ваниль'],
  ['3204', 17.50, 'желтый'],
  ['3209', 17.50, 'лимонный'],
  ['3458', 17.50, 'дюна'],
  ['3499', 17.50, ''],
  ['4059', 17.50, 'кремово-розовый'],
  ['4063', 17.50, 'персиковый'],
  ['4077', 17.50, 'красный'],
  ['4082', 17.50, 'светло-розовый'],
  ['4096', 17.50, ''],
  ['4158', 17.50, 'розовый'],
  ['4201', 17.50, ''],
  ['4261', 17.50, 'абрикосовый'],
  ['4301', 17.50, 'темно-абрикосовый'],
  ['4858', 17.50, 'темно-лиловый'],
  ['4967', 17.50, 'лиловый'],
  ['5150', 17.50, 'светло-голубой'],
  ['5173', 17.50, 'голубой'],
  ['5259', 17.50, 'индиго'],
  ['5608', 17.50, 'светло-зеленый'],
  ['5713', 17.50, ''],
  ['5850', 17.50, 'зеленый матовый'],
  ['5853', 17.50, 'салатовый'],
  ['5992', 17.50, ''],
  ['7005', 18.14, 'металлик'],
  ['7013', 18.14, 'серебро'],
  ['7120', 18.14, ''],
  ['7122', 18.14, 'золото'],
  ['7125', 18.14, 'зернистое золото'],
  ['7128', 18.14, 'красное золото'],
  ['7218', 18.14, 'металлик темно-серый'],
  ['7255', 18.14, 'металлик сиреневый'],
  ['7256', 18.14, 'металлик салатовый'],
  ['7257', 18.14, 'металлик персиковый'],
  ['7258', 18.14, ''],
  ['7259', 18.14, ''],
  ['7260', 18.14, ''],
  ['7261', 18.14, ''],
  ['7282', 18.14, 'металлик голубой'],
  ['7105', 21.30, 'серебро/золото***'],
  ['2549', 25.73, 'чайная роза'],
  ['2259п', 26.92, 'перфорация магнолия'],
  ['7005п', 26.92, 'перфорация металлик'],
  ['0225п', 26.92, 'перфорация белая'],
  ['1908п', 26.92, 'перфорация черный'],
  ['7128п', 26.92, 'перфорация красное золото'],
  ['6009', 32.79, ''],
  ['6010', 32.79, 'сосна'],
  ['6012', 32.79, 'бук'],
  ['6013', 32.79, 'дуб'],
  ['6015', 32.79, 'каштан'],
  ['6016', 32.79, 'тик'],
  ['6017', 32.79, ''],
  ['9002', 32.79, 'белый жемчуг'],
  ['8101', 24.25, 'шагрень'],
  ['8103', 24.25, 'шагрень'],
  ['7419', 27.88, 'лен'],
  ['7431', 27.88, 'лен'],
  ['8011', 27.88, 'полоса'],
  ['8012', 27.88, 'полоса'],
  ['7505', 31.51, 'штрих'],
  ['7525', 31.51, 'штрих'],
  ['7528', 31.51, 'штрих'],
  ['7536', 31.51, 'штрих'],
  ['9013', 25.35, 'перламутр'],
  ['9018', 25.35, 'перламутр'],
  ['9035', 25.35, 'перламутр'],
  ['7718', 40.01, 'кварц'],
  ['7722', 40.01, 'кварц'],
  ['7734', 40.01, 'кварц'],
  ['7754', 32.37, 'кварц графит'],
  ['1853', 21.44, 'матовая'],
  ['1884', 21.44, 'матовая'],
  ['2403', 21.44, 'матовая']
]