import { FC, useEffect, useState } from "react"
import { Form, Row, Col, Button, Accordion, useAccordionButton, InputGroup, Image, Navbar, Nav, Badge } from "react-bootstrap"
import { InputHeight } from "../../InputHeight"
import { InputWidth } from "../../InputWidth"
import { gorizontLenta16 } from "./gorizontLenta16"
import { gorizontLenta25 } from "./gorizontLenta25"

export const CalcGorizontal = () => {

  const [width, setWidth] = useState(1200)
  const [height, setHeight] = useState(1200)
  const [material, setMaterial] = useState('0120')
  const [cena, setCena] = useState(14.12)
  const [result, setResult] = useState(0)

  let massivBattons: any[] = []

  interface Btn {
    children: any
    eventKey: string
  }

  const CustomToggle: FC<Btn> = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey)
    return (
      <Button
        className="m-1"
        variant={"outline-primary"}
        onClick={decoratedOnClick}
      >
        {children}
      </Button>
    )
  }

  const [lenta, setLenta] = useState('gorizontLenta25')
  const lentaObj: any = {
    'gorizontLenta25': gorizontLenta25,
    'gorizontLenta16': gorizontLenta16
  }

  const [check, setCheck] = useState(true)

  lentaObj[lenta].map((item: any, index: number) => {
    let importImg = require(`./${lenta}/${item[0]}.jpg`)
    massivBattons = [...massivBattons,
    <div className="d-grid gap-2" key={item[0]} onClick={() => { setMaterial(item[0]); setCena(item[1]) }}>
      <CustomToggle eventKey='gorizontalCheck' >
        <Navbar className="py-0">
          <Nav className="me-auto">{item[2]}</Nav>
          <Nav><Image rounded height={'40px'} src={importImg} /></Nav>
          <Badge className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-secondary">
            {item[0]}
            <span className="visually-hidden">{item[2]}</span>
          </Badge>
        </Navbar>
      </CustomToggle>
    </div>]
  })

  useEffect(() => {
    setResult(Math.ceil(width * height / 1000000 * cena) * 80 * 1.5)
  }, [width, height, material])



  return (
    <>
      <Form validated>
        <Row>
          <Col>
            <InputWidth onChange={(txt) => setWidth(txt)} max={6000} />
          </Col>
          <Col>
            <InputHeight onChange={(txt) => setHeight(txt)} max={6000} />
          </Col>
        </Row>
        <div key="inline-radio" className="m-3">
          <Row>
            <Col>
              <Form.Check
                inline
                label="25мм"
                name="gorizontal"
                type="radio"
                id="gorizontal25"
                checked={check}
                onChange={() => { setLenta('gorizontLenta25'); setCheck(true); setMaterial('0120'); setCena(14.12) }}
              />
            </Col>
            <Col>
              <Form.Check
                inline
                label="16мм"
                name="gorizontal"
                type="radio"
                id="gorizontal16"
                checked={!check}
                onChange={() => { setLenta('gorizontLenta16'); setCheck(false); setMaterial('0225'); setCena(22.14) }}
              />
            </Col>
          </Row>
        </div>
      </Form>
      <Accordion>
        <Accordion.Item className="m-1" eventKey="gorizontalCheck">
          <Accordion.Header>
            <Image rounded className="me-3" height={'50px'} src={require(`./${lenta}/${material}.jpg`)} />
            {material}
          </Accordion.Header>
          <Accordion.Body>
            {massivBattons}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Button className="m-1" variant={"success"}>{result} руб.</Button>
    </>
  )
}