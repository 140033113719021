import { Card } from "react-bootstrap"
import { AddToCart } from "../../AddToCart"
import { formatCurrency } from "../utilities/formatCurrency"
import './CartStyles/cartStyles.css'

type StoreItemProps = {
  id: number,
  imgUrl: string,
  name: string,
  price: number
}

export const StoreItem = ({ id, imgUrl, name, price }: StoreItemProps) => {
  return (
    <Card className="h-100">
      <Card.Img variant="top" src={imgUrl} height='200px' className='cartimg' />
      <Card.Body className="d-flex flex-column" >
        <Card.Title className="d-flex justify-content-between align-items-baseline mb-4">
          <span className="fs-2">{name}</span>
          <span className="ms-2 text-muted">{formatCurrency(price)}</span>
        </Card.Title>
        <div className="mt-auto">
          <AddToCart id={id}/>
        </div>
      </Card.Body>
    </Card>
  )
}