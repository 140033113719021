import { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { redirect, useNavigate } from "react-router-dom";
import "./Admin.css"

export const AdminPanel = () => {
  const [imgsrc, setImgsrc] = useState('')
  const [imgname, setImgname] = useState('')
  const user = 'antikonst'
  const pass = '87919061'
  const [enterUser, setEnterUser] = useState('')
  const [enterPass, setEnterPass] = useState('')

  function loadFile(e: any) {
    setImgsrc(URL.createObjectURL(e.target.files[0]))
    setImgname(e.target.files[0].name)
  }

  // const navigate = useNavigate();
  // const onSubmit = () => {
  //   setTimeout(navigate("/santehnicheskie_rollety"), 500)
  // }

  const [show, setShow] = useState(true);

  useEffect(() => {
    if (enterUser === user) {
      if (enterPass === pass) {
        setShow(false)
      }
    }

  }, [enterUser, enterPass])

  return (
    <>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>Вход для админа</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-floating mb-3">
            <input maxLength={20} type="text" className="form-control" id="adminname" name="adminname" placeholder="Имя" onChange={(e) => setEnterUser(e.target.value)} />
            <label htmlFor="adminname">Имя</label>
          </div >
          <div className="form-floating mb-3">
            <input maxLength={20} type="text" className="form-control" id="adminpassword" name="adminpassword" placeholder="Пароль" onChange={(e) => setEnterPass(e.target.value)} />
            <label htmlFor="adminpassword">Пароль</label>
          </div >
        </Modal.Body>
      </Modal>
      <form className="m-3" action="add_santehrolls_script.php" method="POST" encType="multipart/form-data">
        <div className="form-floating mb-3">
          <input type="text" className="form-control" id="santeh_title" name="name" placeholder="Заголовок" />
          <label htmlFor="santeh_title">Заголовок</label>
        </div >
        <div className="form-floating mb-3">
          <textarea maxLength={500} className="form-control height_textarea" id="santeh_description" name="description" placeholder="Описание"></textarea>
          <label htmlFor="santeh_description">Описание</label>
        </div>
        <div className="form-floating mb-3">
          <input type="number" className="form-control" id="santeh_width" name="width" placeholder="Ширина" />
          <label htmlFor="santeh_width">Ширина</label>
        </div>
        <div className="form-floating mb-3">
          <input type="number" className="form-control" id="santeh_height" name="height" placeholder="Высота" />
          <label htmlFor="santeh_height">Высота</label>
        </div>
        <div className="form-floating mb-3">
          <input type="number" className="form-control" id="santeh_price" name="price" placeholder="Цена" />
          <label htmlFor="santeh_price">Цена</label>
        </div>
        <div className="form-floating mb-3">
          <select className="form-select" id="santeh_color" name="color">
            <option defaultValue="белый">белый</option>
            <option value="бежевый">бежевый</option>
            <option value="серый">серый</option>
            <option value="серебристый металлик">серебристый металлик</option>
            <option value="коричневый">коричневый</option>
          </select>
          <label htmlFor="santeh_color">Цвет</label>
        </div>
        <div className="row mb-3">
          {window.innerWidth < 600 && <div className="mb-2">{imgname}</div>}
          <div className="form-floating col">
            <input type="file" className="form-control form-control-sm p-3" id="photo" name="photo" accept="image/png, image/jpeg, image/webp" onChange={(event) => loadFile(event)} />
          </div>
          {imgsrc && <div className="col form-floating rounded">
            <img src={imgsrc} alt="" width="100%" height="auto" className="rounded" />
          </div>}
        </div>
        <div className="mb-3 form-check">
          <input type="checkbox" id="santeh_korob" name="korob" value="out" />
          <label className="ps-2" htmlFor="santeh_korob">Короб наружу</label>
        </div>
        <button type="submit" className="btn btn-primary">Загрузить на сайт</button>
      </form >
    </>
  )
}