import { Row, Col } from 'react-bootstrap'
import { StoreItem } from '../components/StoreItem'
import storeItems from '../data/items.json'
import raspashnie from '../data/raspashnie.json'


export const Store = () => {
  return (
    <>
      <h1>Автоматика для откатных ворот</h1>
      <Row md={2} xs={1} lg={3} className="g-3">
        {storeItems.map(item => (
          <Col key={item.id}>
            <StoreItem {...item} />
          </Col>
        ))}
      </Row>
      <h1>Автоматика для распашных ворот</h1>
      <Row md={2} xs={1} lg={3} className="g-3">
        {raspashnie.map(item => (
          <Col key={item.id}>
            <StoreItem {...item} />
          </Col>
        ))}
      </Row>
    </>
  )
}