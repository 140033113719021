export const oglavlenie_menu =
  ['Вертикальные жалюзи',
    'Горизонтальные жалюзи',
    'Рулонные шторы',
    'Шторы-плиссе',
    'Профили роллет',
    'Сантехнические роллеты',
    'Монтаж роллет',
    'Расчёт роллет',
    'Секционные ворота',
    'Рулонные ворота',
    'Откатные ворота',
    'Автоматика']
