import React from 'react'
import { useContext, useState } from 'react'
import { Col, Container, Form, Nav, Navbar, Row, Stack } from 'react-bootstrap'
import { BrowserRouter, Link, Router } from 'react-router-dom'
import { Context } from '../../context'
import { links } from '../../links'
import { Logo } from '../Header/Logo'
import { MenuContact } from '../Header/MenuContact'
import { NaGlavnuyu } from '../NaGlavnuyu'
import { SendTg } from '../SendTg'
import './Footer.css'

export const Footer = () => {

  const { takeNum, takeHasMore, counts } = useContext<any>(Context)
  const [text, setText] = useState('')

  const onchange = (event: any) => {
    let e = event.target.value
    e.length < 100 ? setText(e) : setText(e.slice(0, 100))
  }

  const li = (num: number, text: string) => <li className="nav-item mb-2"><Link state={{ counts: counts }} to={links[num]} reloadDocument className="nav-link p-0 text-muted">{text}</Link></li>

  return (
    <>
      <div className="container">
        <footer className="py-5">
          <div className="row">
            <BrowserRouter>
              <div className="col-6 col-md-2 mb-3">
                <h5>Жалюзи</h5>
                <ul className="nav flex-column">
                  {li(0, 'Вертикальные')}
                  {li(1, 'Горизонтальные')}
                  {li(2, 'Рулонные шторы')}
                  {li(3, 'Шторы-плиссе')}
                </ul>
              </div>

              <div className="col-6 col-md-2 mb-3">
                <h5>Роллеты</h5>
                <ul className="nav flex-column">
                  {li(4, 'Профили')}
                  {li(5, 'СанТех')}
                  {li(6, 'Монтаж')}
                  {li(7, 'Расчёт')}
                </ul>
              </div>

              <div className="col-6 col-md-2 mb-3">
                <h5>Ворота</h5>
                <ul className="nav flex-column">
                  {li(8, 'Секционные')}
                  {li(9, 'Рулонные')}
                  {li(10, 'Откатные')}
                  {li(11, 'АВТОМАТИКА')}
                </ul>
              </div>
            </BrowserRouter>
            <div className="col-md-5 offset-md-1 mb-3">
              <form action="./send.php">
                <h5>Форма обратной связи</h5>
                <p>Отправьте нам Ваш контакт и мы свяжемся с Вами в ближайшее время!</p>
                <div className="d-flex flex-column flex-sm-row w-100 gap-2">
                  <Form.Floating className="m-0">
                    <Form.Control
                      type="text"
                      placeholder="Ваш контакт"
                      required
                      onChange={onchange}
                    />
                    <label htmlFor="roll_width">Ваш контакт</label>
                    <Form.Control.Feedback type="invalid">
                    </Form.Control.Feedback>
                  </Form.Floating>
                  {/* <button className="btn btn-primary" type="button">Отправить</button> */}
                  <SendTg text={text} photo='http://gerart.spb.ru/logo192.png' btnname={'отправить'} />
                </div>
                {/* <textarea className="d-none" name="imageData" value={imgtg} readOnly>{imgtg}</textarea>
                <textarea className="d-none" name="datenow" value={datenow} readOnly>{datenow}</textarea> */}
              </form>
            </div>
          </div>

          {/* <Stack direction="horizontal" gap={3}>
            <Logo />
            <p className="me-auto" >&copy; 2023 GerArt, Inc. All rights reserved.</p>
            <MenuContact />
          </Stack> */}
          <Navbar expand="lg">
            <Nav className="me-auto">
              <NaGlavnuyu text={'GerArt'} classnames={'me-3'} />
              &copy; 2023г. Все права защищены.
            </Nav>
            <Nav className="min768 me-auto"><MenuContact /></Nav>
          </Navbar>
        </footer>
      </div>
    </>
  )
}