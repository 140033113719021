import { Button, Container } from 'react-bootstrap';
import { Cards } from '../Cards'
import { FC, useContext, useEffect, useState } from 'react'
import { ModalBootstrap } from '../ModalBootstrap';
import { MasonryUniversal } from '../Masonry_universal';
import './CardsBlock.css'
import { VertikalInAccordion } from '../Body/Accordion/VertikalInAccordion';
import { Context } from '../../context';
import { GorizontalAccordion } from '../Body/Accordion/GorizontalAccordion';
import { RulonkaAccordion } from '../Body/Accordion/RulonkaAccordion';
import { PlisseAccordion } from '../Body/Accordion/PlisseAccordion';
import { BrowserRouter, Link, Route, Router, Routes, useLocation } from 'react-router-dom';
import { CalcRollet } from '../Calcs/CalcRollet';
import { links } from '../../links';
import { texts } from '../../texts';
import React from 'react';
import { useSmoothScrolling } from '../../func/smoothScrolling';
import { Store } from '../ShopingCart/pages/Store';
import santehrolls from '../../jsons/santehrolls.json';
import { SantehRolls } from '../SantehRolls/SantehRolls';
import { AdminPanel } from '../Admin';
import { Helmet, HelmetProvider } from "react-helmet-async";
import { SV_trend_calc } from '../SV_trend_calc';

export const CardsBlock = () => {

  const { numCardBlock, hasMoreCards, searchBlock } = useContext<any>(Context)
  const img_with_modal = (src: string | undefined) => <img alt='' src={src} className="rounded bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto" width="500" height="500" />

  const podrobnee = <div className="d-grid gap-2 mt-2">
    <Button
      variant="outline-primary"
      href={links[11]}
    >...подробнее</Button>
  </div>

  const modal_vert_img_from_image = <ModalBootstrap
    title=""
    btn_txt={img_with_modal('/imgs/card_images/vertikal_zhaluzi.webp')}
    children={
      <MasonryUniversal
        id={'vert_img_from_image'}
        path_images={'./vert/'}
        n={15} />
    } />

  const modal_roll_img = <ModalBootstrap
    title=""
    btn_txt="галерея"
    children={
      <MasonryUniversal
        id={'rollety'}
        path_images={'./rollety/'}
        n={33} />
    } />

  const modal_roll_img_from_img = <ModalBootstrap
    title=""
    btn_txt={img_with_modal('/imgs/card_images/rolleta.webp')}
    children={
      <MasonryUniversal
        id={'rollety'}
        path_images={'./rollety/'}
        n={33} />
    } />

  const modal_rulon_img = <ModalBootstrap
    title=""
    btn_txt={img_with_modal('/imgs/card_images/rulonka.webp')}
    children={
      <MasonryUniversal
        id={'rulonka'}
        path_images={'./rulonka/rulonka'}
        n={10} />
    } />

  const modal_section_vorota_img = <ModalBootstrap
    title=""
    btn_txt="галерея"
    children={
      <MasonryUniversal
        id={'section_vorota'}
        path_images={'./section_vorota/vorota'}
        n={16} />
    } />

  const modal_section_vorota_img_from_image = <ModalBootstrap
    title=""
    btn_txt={img_with_modal('/imgs/card_images/section_vorota.webp')}
    children={
      <MasonryUniversal
        id={'section_vorota'}
        path_images={'./section_vorota/vorota'}
        n={16} />
    } />

  const modal_gorizontal_img = <ModalBootstrap
    title=""
    btn_txt={img_with_modal('/imgs/card_images/gorizontalka.webp')}
    children={
      <MasonryUniversal
        id={'gor'}
        path_images={'./gor/'}
        n={17} />
    } />

  const modal_plisse_img = <ModalBootstrap
    title=""
    btn_txt={img_with_modal('/imgs/card_images/plisse.webp')}
    children={
      <MasonryUniversal
        id={'plisse'}
        path_images={'./plisse/plisse'}
        n={12} />
    } />

  // useEffect(() => {
  //   const elem = items.find(i => i.props.id === window.location.href.slice(window.location.href.lastIndexOf('/')))
  //   console.log(elem?.props.children[1].props.h2)
  //   document.title = elem?.props.children[1].props.h2
  // }, [window.location.href])

  let items = [
    <div key={0} className={"hidden"} id={links[0]} >
      <Helmet>
        <title>Вертикальные жалюзи</title>
        <meta name="description" content={texts[0]} />
      </Helmet>
      <Cards
        h2={"Вертикальные жалюзи."}
        h2_muted={'Просто и уютно'}
        text={<>{texts[0]}
          <div className='my-3'>
            <VertikalInAccordion />
          </div>
        </>}
        right={false}
        child={modal_vert_img_from_image}
      />
    </div>,
    <div key={1} className={"hidden"} id={links[1]} >
      <Helmet>
        <title>Горизонтальные жалюзи</title>
        <meta name="description" content={texts[1]} />
      </Helmet>
      <Cards
        h2={"Горизонтальные жалюзи."}
        h2_muted={'Классика жанра'}
        text={<>{texts[1]}
          <div className='my-3'>
            <GorizontalAccordion />
          </div>
        </>}
        right={true}
        child={modal_gorizontal_img}
      />
    </div>,
    <div key={2} className={"hidden"} id={links[2]}>
      <Helmet>
        <title>Рулонные шторы</title>
        <meta name="description" content={texts[2]} />
      </Helmet>
      <Cards
        h2={"Рулонные шторы."}
        h2_muted={'Отличное решение'}
        text={<>{texts[2]}
          <div className='my-3'>
            <RulonkaAccordion />
          </div>
        </>}
        right={false}
        child={modal_rulon_img}
      />
    </div>,
    <div key={3} className={"hidden"} id={links[3]}>
      <Helmet>
        <title>Шторы-плиссе</title>
        <meta name="description" content={texts[3]} />
      </Helmet>
      <Cards
        h2={"Шторы-плиссе."}
        h2_muted={'В любой плоскости'}
        text={<>{texts[3]}
          <div className='my-3'>
            <PlisseAccordion />
          </div>
        </>}
        right={true}
        child={modal_plisse_img}
      />
    </div>,
    <div key={4} className={"hidden"} id={links[4]}>
      <Helmet>
        <title>Рольставни</title>
        <meta name="description" content={texts[4]} />
      </Helmet>
      <Cards
        h2={"Рольставни."}
        h2_muted={'Защита и комфорт'}
        text={<>
          {texts[4]}
          <div className='mb-2'></div>
          {modal_roll_img}
          <div className='mb-2'></div>
        </>}
        child={modal_roll_img_from_img}
        right={false} />
    </div>,
    <div key={5} className={"hidden"} id={links[5]} >
      <Helmet>
        <title>Сантехнические роллеты</title>
        <meta name="description" content="Алюминиевые сантехнические роллеты – это защитные конструкции, изготовленные из прочного и долговечного материала – алюминия. Они предназначены для защиты ванных комнат, туалетов и кухонь от протечек воды, запахов и шума. Алюминиевые сантехнические роллеты имеют различные дизайны, цвета и формы, что позволяет подобрать оптимальный вариант для любого интерьера" />
      </Helmet>
      {window.location.href.slice(window.location.href.lastIndexOf('/'), window.location.href.length) === links[5] ||
        window.location.href.slice(window.location.href.lastIndexOf('/'), window.location.href.length) === links[5] + '#' ?
        <SantehRolls /> :
        <Button href={links[5]} variant="light" >
          <Cards
            h2={"Сантехнические роллеты."}
            h2_muted={'Просто и доступно'}
            text={<>{santehrolls[0].description}</>}
            src='/imgs/card_images/raschet_rollet.webp'
            right={true} />
        </Button>
      }
    </div>,
    <div key={6} className={"hidden"} id={links[6]}>
      <Helmet>
        <title>Монтаж роллет</title>
        <meta name="description" content={texts[6]} />
      </Helmet>
      <Cards
        h2={"Монтаж роллет."}
        h2_muted={'Профессиональное исполнение'}
        text={<>{texts[6]}</>}
        src='/imgs/card_images/montazh_rollet.webp'
        right={false} />
    </div>,
    <div key={7} className={"hidden"} id={links[7]}>
      <Helmet>
        <title>Расчёт роллет</title>
        <meta name="description" content={texts[7]} />
      </Helmet>
      <Cards
        h2={"Расчёт роллет."}
        h2_muted={'Здесь и сейчас'}
        text={<>
          {texts[7]}
          <CalcRollet test={{ some: '' }} />
          <div className='mb-2'></div>
        </>}
        src={santehrolls[8].photo}
        right={true} />
    </div>,
    <div key={8} className={"hidden"} id={links[8]}>
      <Helmet>
        <title>Секционные ворота</title>
        <meta name="description" content={texts[8]} />
      </Helmet>
      <Cards
        h2={"Секционные ворота."}
        h2_muted={'Идеи для дома'}
        text={<>
          {texts[8]}
          <div className='mb-2'></div>
          {modal_section_vorota_img}
          <div className='mb-2'></div>
          <SV_trend_calc />
        </>}
        child={modal_section_vorota_img_from_image}
        right={false} />
    </div>,
    <div key={9} className={"hidden"} id={links[9]}>
      <Helmet>
        <title>Рулонные ворота</title>
        <meta name="description" content={texts[9]} />
      </Helmet>
      <Cards
        h2={"Рулонные ворота."}
        h2_muted={'Экономия пространства'}
        text={<>{texts[9]}</>}
        src='/imgs/card_images/rulon_vorota.webp'
        right={true} />
    </div>,
    <div key={10} className={"hidden"} id={links[10]}>
      <Helmet>
        <title>Откатные ворота</title>
        <meta name="description" content={texts[10]} />
      </Helmet>
      <Cards
        h2={"Откатные ворота."}
        h2_muted={'Комфортная эксплуатация'}
        text={<>{texts[10]}</>}
        src='/imgs/card_images/otkatnie_vorota.webp'
        right={false} />
    </div>,
    <div key={11} className={"hidden"} id={links[11]}>
      <Helmet>
        <title>Автоматика</title>
        <meta name="description" content={texts[11]} />
      </Helmet>
      <Cards
        h2={"Автоматика."}
        h2_muted={'Удобно и безопасно'}
        text={<>{texts[11]} {podrobnee}</>}
        src='/imgs/card_images/auto.webp'
        right={true} />
    </div>,
    <Helmet>
      <title>GerArt</title>
      <meta name="description" content="Сайт, на котором удобно ознакомиться, рассчитать и заказать жалюзи, роллеты и ворота" />
    </Helmet>
  ]

  let itemsArray = hasMoreCards ? <><Helmet><title>GerArt</title><meta name="description" content="Сайт, на котором удобно ознакомиться, рассчитать и заказать жалюзи, роллеты и ворота" /></Helmet>{items}</> : <hr key={99999} className="featurette-divider" />

  useSmoothScrolling()


  return (
    <>
      <HelmetProvider>

        <Container>
          <BrowserRouter basename='/'>
            <Routes>
              <Route path="*" element={<h3>404</h3>} />
              <Route path={"/addsantehrolls"} element={<AdminPanel />} />
              <Route path={links[0]} element={items[0]} />
              <Route path={links[1]} element={items[1]} />
              <Route path={links[2]} element={items[2]} />
              <Route path={links[3]} element={items[3]} />
              <Route path={links[4]} element={items[4]} />
              <Route path={links[5]} element={items[5]} />
              <Route path={links[6]} element={items[6]} />
              <Route path={links[7]} element={items[7]} />
              <Route path={links[8]} element={items[8]} />
              <Route path={links[9]} element={items[9]} />
              <Route path={links[10]} element={items[10]} />
              <Route path={links[11]} element={<Store />} />
              <Route path={links[12]} element={[items[0], items[1], items[2], items[3]]} />
              <Route path={links[13]} element={[items[8], items[9], items[10]]} />
              <Route path="/rezultat_poiska" element={<>{searchBlock}</>} />
              <Route path="/" element={itemsArray} errorElement={<h3>404</h3>} />
              {/* <Route path="/add" element={<AddTutorial />} /> */}
              {/* <Route path="/tutorials/:id" element={<Tutorial/>} /> */}
              {/* <Route path="/tutorials/:id" element={<TutorialsListFunc />} />
            <Route path="/tutorials" element={<TutorialsList />} />
            <Route path="/tutorial-list" element={<TutorialsListFunc />} /> */}
            </Routes>
          </BrowserRouter>
        </Container>
      </HelmetProvider>
    </>
  );
}