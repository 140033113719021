import { FC } from 'react'
import { Badge, Button, Navbar } from 'react-bootstrap'
import { ReactComponent as Basketsvg } from '../img/basket.svg'

interface Props {
  count: any
  onclick: any
}

export const Basket:FC<Props> = ({count, onclick}) => {
  return (
    <Button variant='outline-primary' onClick={onclick}>
      <Navbar className='m-0 p-0'>
        <Basketsvg width={40} height={40} fill='currentColor' />
        <Badge bg="secondary" className='position-absolute top-0 start-100 translate-middle rounded-pill'>
          {count}
          <span className="visually-hidden">добавлено в корзину</span>
        </Badge>
      </Navbar>
    </Button>
  )
}