export const gorizontLenta16:any = [
['0225',22.14,'белая глянцевая'],
['1606',25.13,'светло-серый'],
['2259',25.13,'магнолия'],
['2261',25.13,'светло-бежевый'],
['2406',25.13,'темно-бежевый'],
['2871',25.13,'коричневый'],
['3144',25.13,'ваниль'],
['4063',25.13,'персиковый'],
['7005',25.13,'металлик'],
['7013',25.13,'серебро'],
['7122',25.13,'золото'],
['7128',25.13,'красное золото'],
['6012',47.57,'под дерево бук'],
['6013',47.57,'под дерево дуб'],
]