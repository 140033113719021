import { Col } from "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css'
import { FC } from "react"

interface Props {
  name: string
  children: any
  url: string
  func: () => void
  href: string
}

export const ColBody: FC<Props> = ({ name, children, url, func, href }) => {


  return (
    <Col className="text-center" lg={4}>
      <a href={href} className="dropdown-item">
        <img alt="" className="bd-placeholder-img rounded-circle" width="140" height="140" src={url} onClick={func}></img>
        <h2 className="fw-normal">{name}</h2>
      </a>
      {children}
    </Col>
  )
}