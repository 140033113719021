import { FC } from "react"
import price from "../../jsons/alutech_rolls_price.json"
import { formatCurrency } from "../../utilities/formatCurrency"

interface PropsCalcRollsPd45 {
  width: number
  height: number
}

export const CalcRollsPd45: FC<PropsCalcRollsPd45> = ({ width, height }) => {

  const w = width / 1000
  const h = height / 1000
  const s = w * h
  const num_lamaley = Math.floor(height / 45)


  const PD_45N = price.find(i => i.art === "PD/45N")
  const ES9x45RI_eco = price.find(i => i.art === "ES9x45RI/eco")
  const SB45_165 = price.find(i => i.art === "SB45/165")
  const RT40x0_5 = price.find(i => i.art === "RT40x0,5")
  const GR53x22I_eco = price.find(i => i.art === "GR53x22I/eco")
  const SF45_165u = price.find(i => i.art === "SF45/165u")
  const SL = price.find(i => i.art === "SL")
  const SS1301 = price.find(i => i.art === "SS1301")
  const RD40 = price.find(i => i.art === "RD40")
  const GD9_U = price.find(i => i.art === "GD9/U")
  const KU40 = price.find(i => i.art === "KU40")
  const SP45PD = price.find(i => i.art === "SP45PD")
  const BB12x28 = price.find(i => i.art === "BB12x28")
  const PP12 = price.find(i => i.art === "PP12")
  const ST40 = price.find(i => i.art === "ST40")
  const SIM04_8 = price.find(i => i.art === "4SIM04/8")
  const PLA100 = price.find(i => i.art === "PLA100")

  const summ_width = (RT40x0_5!.stoimost + SB45_165!.stoimost + ES9x45RI_eco!.stoimost + PD_45N!.stoimost * num_lamaley) * w
  const summ_height = GR53x22I_eco!.stoimost * 2 * h
  const summ_stat = SF45_165u!.stoimost + SL!.stoimost + SS1301!.stoimost * 10 + RD40!.stoimost * 10 + GD9_U!.stoimost + KU40!.stoimost + SP45PD!.stoimost * num_lamaley + BB12x28!.stoimost + PP12!.stoimost * 10 + ST40!.stoimost * 2 + SIM04_8!.stoimost + PLA100!.stoimost

  const summ = summ_width + summ_height + summ_stat

  return <>{formatCurrency(summ)}</>
}