export const links = [
  '/vertikal_zhaluzi',
  '/gorizontal_zhaluzi',
  '/rulonnye_shtory',
  '/shtory_plisse',
  '/profil_rollet',
  '/santehnicheskie_rollety',
  '/montazh_rollet',
  '/calculator_rollet',
  '/section_vorota',
  '/rulonnie_vorota',
  '/otkatnie_vorota',
  '/automatika',
  '/zhaluzi',
  '/vorota'
]