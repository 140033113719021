import './App.css'
import { TopSlider } from './components/TopSlider';
import { Body } from './components/Body'
import { CardsBlock } from './components/CardsBlock';
import { ToTop } from './components/ToTop'
import { Footer } from './components/Footer'
import { Header } from './components/Header';
import { useEffect, useRef, useState } from 'react';
import { Context } from './context';
import { ResultSearch } from './components/ResultSearch';
import { Container } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from './UlbiTV/hooks/redux'
import { useLocation } from 'react-router-dom';
import { userSlice } from './UlbiTV/store/reducers/UserSlice';
import { ShopingCartProvider } from './components/ShopingCart/context/ShopingCartContext';
import { Helmet } from 'react-helmet';

function App() {

  const [numCardBlock, setNumCardBlock] = useState(0)
  const [hasMoreCards, setHasMoreCards] = useState(true)
  // const [windowscrollY, setwindowscrollY] = useState(window.scrollY)
  // const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const [headRolletClick, setHeadRolletClick] = useState(false)
  const [accordionClickOpen, setAccordionClickOpen] = useState(true)
  const [searchBlock, setSearchBlock] = useState(<></>)
  const [counts, setCounts] = useState(0)
  const [data, setData] = useState(null)

  const { count } = useAppSelector(state => state.userReducer)
  const { increment } = userSlice.actions
  const dispatch = useAppDispatch()
  //console.log(increment(counts))

  // useEffect(() => {
  //   fetch('/api')
  //   .then(res => res.json())
  //   .then(res => setData(res[0]['message']))
  // }, [])

  const takeNum = (n: number) => {
    setNumCardBlock(n)
  }

  const takeHasMore = (b: boolean) => {
    setHasMoreCards(b)
  }

  const clickHeadCalcRollet = (b: boolean) => {
    setHeadRolletClick(b)
  }

  const vidim = hasMoreCards ? <></> : <Container>{searchBlock}</Container>

  return (
    <ShopingCartProvider>
      <Context.Provider value={{
        takeNum,
        hasMoreCards,
        takeHasMore,
        clickHeadCalcRollet,
        headRolletClick,
        accordionClickOpen,
        setAccordionClickOpen,
        numCardBlock,
        setNumCardBlock,
        searchBlock,
        setSearchBlock,
        counts,
        setCounts
      }}>
        {/* <Helmet>
          <title>My Title</title>
          <meta name="description" content="Helmet application" />
        </Helmet> */}
        <Header countBasket={count} />
        {/* <p>{!data ? "load..." : data}</p> */}
        <TopSlider />
        {vidim}
        <Body />
        <CardsBlock />
        <Footer />
        <ToTop />
      </Context.Provider>
    </ShopingCartProvider>
  );
}

export default App;
