export const maxHeights: any = [
  {
    "name": "Авенсис 240 см",
    "UNI1": "2.43",
    "UNI2": "2.43",
    "UNI2спружиной": "1.85",
    "MINI/ROLLA19": "2.43",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.58",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Ажур 220 см",
    "UNI1": "3.04",
    "UNI2": "3.04",
    "UNI2спружиной": "2.33",
    "MINI/ROLLA19": "3.04",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "3.25",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Аллея 250 см",
    "UNI1": "2.30",
    "UNI2": "2.30",
    "UNI2спружиной": "1.75",
    "MINI/ROLLA19": "2.30",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.44",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "5.74",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Альмерия 240 см",
    "UNI1": "2.66",
    "UNI2": "2.66",
    "UNI2спружиной": "2.03",
    "MINI/ROLLA19": "2.66",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.83",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Альфа 200 см, 250 см",
    "UNI1": "2.50",
    "UNI2": "2.50",
    "UNI2спружиной": "1.91",
    "MINI/ROLLA19": "2.50",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.66",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Альфа Alu BO 250 см",
    "UNI1": "2.24",
    "UNI2": "2.24",
    "UNI2спружиной": "1.70",
    "MINI/ROLLA19": "2.24",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.37",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "5.59",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Альфа ВО 250 см",
    "UNI1": "1.47",
    "UNI2": "1.47",
    "UNI2спружиной": "1.11",
    "MINI/ROLLA19": "1.84",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "1.93",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "4.60",
    "MGстальн.крон.": "5.70",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.13"
  },
  {
    "name": "Анжу 200 см",
    "UNI1": "2.43",
    "UNI2": "2.43",
    "UNI2спружиной": "1.85",
    "MINI/ROLLA19": "2.43",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.58",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Антарес ВО 300 см",
    "UNI1": "1.53",
    "UNI2": "1.53",
    "UNI2спружиной": "1.16",
    "MINI/ROLLA19": "1.53",
    "AMG32": "5.24",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "1.59",
    "AMG45кассета": "5.06",
    "AMGДень-Ночь32": "5.24",
    "AMGДень-Ночь45": "3.83",
    "MGстальн.крон.": "4.77",
    "MGпласт.крон./монт.профиль": "5.13",
    "ROOF": "1.17"
  },
  {
    "name": "Ариадна 195 см",
    "UNI1": "4.04",
    "UNI2": "4.04",
    "UNI2спружиной": "3.10",
    "MINI/ROLLA19": "4.04",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "4.35",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Бабочки 195 см",
    "UNI1": "4.04",
    "UNI2": "4.04",
    "UNI2спружиной": "3.10",
    "MINI/ROLLA19": "4.04",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "4.35",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Бамбук 240 см",
    "UNI1": "2.58",
    "UNI2": "2.58",
    "UNI2спружиной": "1.97",
    "MINI/ROLLA19": "2.58",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.75",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Бланш 235 см",
    "UNI1": "1.65",
    "UNI2": "1.65",
    "UNI2спружиной": "1.25",
    "MINI/ROLLA19": "1.65",
    "AMG32": "5.65",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "1.73",
    "AMG45кассета": "5.47",
    "AMGДень-Ночь32": "5.65",
    "AMGДень-Ночь45": "4.14",
    "MGстальн.крон.": "5.14",
    "MGпласт.крон./монт.профиль": "5.54",
    "ROOF": "1.27"
  },
  {
    "name": "Блюз 200 см",
    "UNI1": "2.50",
    "UNI2": "2.50",
    "UNI2спружиной": "1.91",
    "MINI/ROLLA19": "2.50",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.66",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Болгарская роза 200 см",
    "UNI1": "2.24",
    "UNI2": "2.24",
    "UNI2спружиной": "1.70",
    "MINI/ROLLA19": "2.24",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.37",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "5.59",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Бостон 250 см",
    "UNI1": "2.07",
    "UNI2": "2.07",
    "UNI2спружиной": "1.58",
    "MINI/ROLLA19": "2.07",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.18",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "5.18",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.59"
  },
  {
    "name": "Бухара ВО 210 см",
    "UNI1": "2.07",
    "UNI2": "2.07",
    "UNI2спружиной": "1.58",
    "MINI/ROLLA19": "2.07",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.18",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "5.18",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.59"
  },
  {
    "name": "Бьянка 240 см",
    "UNI1": "2.58",
    "UNI2": "2.58",
    "UNI2спружиной": "1.97",
    "MINI/ROLLA19": "2.58",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.75",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Венеция 240 см",
    "UNI1": "2.43",
    "UNI2": "2.43",
    "UNI2спружиной": "1.85",
    "MINI/ROLLA19": "2.43",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.58",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Винтаж 200 см",
    "UNI1": "2.50",
    "UNI2": "2.50",
    "UNI2спружиной": "1.91",
    "MINI/ROLLA19": "2.50",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.66",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Вуаль 240 см",
    "UNI1": "3.27",
    "UNI2": "3.27",
    "UNI2спружиной": "2.51",
    "MINI/ROLLA19": "3.27",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "3.51",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Гала ВО 230 см",
    "UNI1": "2.07",
    "UNI2": "2.07",
    "UNI2спружиной": "1.58",
    "MINI/ROLLA19": "2.07",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.18",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "5.18",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.59"
  },
  {
    "name": "Гармония 180 см",
    "UNI1": "2.18",
    "UNI2": "2.18",
    "UNI2спружиной": "1.66",
    "MINI/ROLLA19": "2.18",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.30",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "5.45",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.68"
  },
  {
    "name": "Глиттер 240 см",
    "UNI1": "2.24",
    "UNI2": "2.24",
    "UNI2спружиной": "1.70",
    "MINI/ROLLA19": "2.24",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.37",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "5.59",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Глиттер ВО 240 см",
    "UNI1": "1.92",
    "UNI2": "1.92",
    "UNI2спружиной": "1.46",
    "MINI/ROLLA19": "1.92",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.03",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "4.82",
    "MGстальн.крон.": "5.96",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.48"
  },
  {
    "name": "Гуана 200 см",
    "UNI1": "2.58",
    "UNI2": "2.58",
    "UNI2спружиной": "1.97",
    "MINI/ROLLA19": "2.58",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.75",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Даллас 240 см",
    "UNI1": "3.54",
    "UNI2": "3.54",
    "UNI2спружиной": "2.71",
    "MINI/ROLLA19": "3.54",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "3.81",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Джунгли 240 см",
    "UNI1": "4.04",
    "UNI2": "4.04",
    "UNI2спружиной": "3.10",
    "MINI/ROLLA19": "4.04",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "4.35",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Импала 240 см",
    "UNI1": "2.12",
    "UNI2": "2.12",
    "UNI2спружиной": "1.62",
    "MINI/ROLLA19": "2.12",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.24",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "5.31",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.64"
  },
  {
    "name": "Калипсо 200 см",
    "UNI1": "2.24",
    "UNI2": "2.24",
    "UNI2спружиной": "1.70",
    "MINI/ROLLA19": "2.24",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.37",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "5.59",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Камелия 280 см",
    "UNI1": "2.84",
    "UNI2": "2.84",
    "UNI2спружиной": "2.17",
    "MINI/ROLLA19": "2.84",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "3.03",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Каролина 240 см",
    "UNI1": "2.50",
    "UNI2": "2.50",
    "UNI2спружиной": "1.91",
    "MINI/ROLLA19": "2.50",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.66",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Крис 220 см",
    "UNI1": "2.75",
    "UNI2": "2.75",
    "UNI2спружиной": "2.10",
    "MINI/ROLLA19": "2.75",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.93",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Крис ВО 220 см",
    "UNI1": "1.72",
    "UNI2": "1.72",
    "UNI2спружиной": "1.31",
    "MINI/ROLLA19": "1.72",
    "AMG32": "5.89",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "1.80",
    "AMG45кассета": "5.69",
    "AMGДень-Ночь32": "5.89",
    "AMGДень-Ночь45": "4.32",
    "MGстальн.крон.": "5.35",
    "MGпласт.крон./монт.профиль": "5.76",
    "ROOF": "1.32"
  },
  {
    "name": "Лен 200 см",
    "UNI1": "2.36",
    "UNI2": "2.36",
    "UNI2спружиной": "1.80",
    "MINI/ROLLA19": "2.36",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.51",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "5.90",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Лен ВО 200 см",
    "UNI1": "1.59",
    "UNI2": "1.59",
    "UNI2спружиной": "1.20",
    "MINI/ROLLA19": "1.59",
    "AMG32": "5.44",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "1.65",
    "AMG45кассета": "5.26",
    "AMGДень-Ночь32": "5.44",
    "AMGДень-Ночь45": "3.98",
    "MGстальн.крон.": "4.95",
    "MGпласт.крон./монт.профиль": "5.33",
    "ROOF": "1.22"
  },
  {
    "name": "Лима Перла 240 см",
    "UNI1": "2.18",
    "UNI2": "2.18",
    "UNI2спружиной": "1.66",
    "MINI/ROLLA19": "2.18",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.30",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "5.45",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.68"
  },
  {
    "name": "Лина 220 см",
    "UNI1": "4.04",
    "UNI2": "4.04",
    "UNI2спружиной": "3.10",
    "MINI/ROLLA19": "4.04",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "4.35",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Лина ВО 220 см",
    "UNI1": "2.07",
    "UNI2": "2.07",
    "UNI2спружиной": "1.58",
    "MINI/ROLLA19": "2.07",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.18",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "5.18",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.59"
  },
  {
    "name": "Манила 200 см",
    "UNI1": "2.07",
    "UNI2": "2.07",
    "UNI2спружиной": "1.58",
    "MINI/ROLLA19": "2.07",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.18",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "5.18",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.59"
  },
  {
    "name": "Марципан 280 см",
    "UNI1": "2.43",
    "UNI2": "2.43",
    "UNI2спружиной": "1.85",
    "MINI/ROLLA19": "2.43",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.58",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Мемфис 300 см",
    "UNI1": "1.88",
    "UNI2": "1.88",
    "UNI2спружиной": "1.43",
    "MINI/ROLLA19": "1.88",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "1.98",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "4.71",
    "MGстальн.крон.": "5.83",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.45"
  },
  {
    "name": "Невада 200 см",
    "UNI1": "1.59",
    "UNI2": "1.59",
    "UNI2спружиной": "1.20",
    "MINI/ROLLA19": "1.59",
    "AMG32": "5.44",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "1.65",
    "AMG45кассета": "5.26",
    "AMGДень-Ночь32": "5.44",
    "AMGДень-Ночь45": "3.98",
    "MGстальн.крон.": "4.95",
    "MGпласт.крон./монт.профиль": "5.33",
    "ROOF": "1.22"
  },
  {
    "name": "Ника 220 см",
    "UNI1": "1.80",
    "UNI2": "1.80",
    "UNI2спружиной": "1.37",
    "MINI/ROLLA19": "1.80",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "1.89",
    "AMG45кассета": "5.94",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "4.50",
    "MGстальн.крон.": "5.58",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.38"
  },
  {
    "name": "Нова 200 см",
    "UNI1": "2.94",
    "UNI2": "2.94",
    "UNI2спружиной": "2.25",
    "MINI/ROLLA19": "2.94",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "3.14",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Омега 200, 250, 300 см",
    "UNI1": "2.50",
    "UNI2": "2.50",
    "UNI2спружиной": "1.91",
    "MINI/ROLLA19": "2.94",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "3.14",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Омега Лайт 260 см",
    "UNI1": "2.75",
    "UNI2": "2.75",
    "UNI2спружиной": "2.10",
    "MINI/ROLLA19": "2.75",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.93",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Омега ВО 250, 300 см",
    "UNI1": "1.72",
    "UNI2": "1.72",
    "UNI2спружиной": "1.31",
    "MINI/ROLLA19": "2.18",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.30",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "5.45",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.32"
  },
  {
    "name": "Омега FR 250 см",
    "UNI1": "2.24",
    "UNI2": "2.24",
    "UNI2спружиной": "1.70",
    "MINI/ROLLA19": "2.24",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.37",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "5.59",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Омега FR BО 250 см",
    "UNI1": "1.80",
    "UNI2": "1.80",
    "UNI2спружиной": "1.37",
    "MINI/ROLLA19": "1.80",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "1.89",
    "AMG45кассета": "5.94",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "4.50",
    "MGстальн.крон.": "5.58",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.38"
  },
  {
    "name": "Осло ВО 220 см",
    "UNI1": "1.59",
    "UNI2": "1.59",
    "UNI2спружиной": "1.20",
    "MINI/ROLLA19": "1.59",
    "AMG32": "5.44",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "1.65",
    "AMG45кассета": "5.26",
    "AMGДень-Ночь32": "5.44",
    "AMGДень-Ночь45": "3.98",
    "MGстальн.крон.": "4.95",
    "MGпласт.крон./монт.профиль": "5.33",
    "ROOF": "1.22"
  },
  {
    "name": "Пергам 200 см",
    "UNI1": "2.30",
    "UNI2": "2.30",
    "UNI2спружиной": "1.75",
    "MINI/ROLLA19": "2.30",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.44",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "5.74",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Перл 250 см",
    "UNI1": "2.58",
    "UNI2": "2.58",
    "UNI2спружиной": "1.97",
    "MINI/ROLLA19": "2.58",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.75",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Перл Лайт 260 см",
    "UNI1": "2.50",
    "UNI2": "2.50",
    "UNI2спружиной": "1.91",
    "MINI/ROLLA19": "2.50",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.66",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Пленка ПЭТ прозрачная\r\n0.1 мм 152 см",
    "UNI1": "6.00",
    "UNI2": "6.00",
    "UNI2спружиной": "6.00",
    "MINI/ROLLA19": "6.00",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "5.80",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Плэйн 200 см",
    "UNI1": "2.94",
    "UNI2": "2.94",
    "UNI2спружиной": "2.25",
    "MINI/ROLLA19": "2.94",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "3.14",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Плэйн ВО 200 см",
    "UNI1": "2.30",
    "UNI2": "2.30",
    "UNI2спружиной": "1.75",
    "MINI/ROLLA19": "2.30",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.44",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "5.74",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Прованс 260 см",
    "UNI1": "1.42",
    "UNI2": "1.42",
    "UNI2спружиной": "1.07",
    "MINI/ROLLA19": "1.72",
    "AMG32": "5.89",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "1.80",
    "AMG45кассета": "5.69",
    "AMGДень-Ночь32": "5.89",
    "AMGДень-Ночь45": "4.32",
    "MGстальн.крон.": "5.35",
    "MGпласт.крон./монт.профиль": "5.76",
    "ROOF": "1.09"
  },
  {
    "name": "Птички 200 см",
    "UNI1": "2.66",
    "UNI2": "2.66",
    "UNI2спружиной": "2.03",
    "MINI/ROLLA19": "2.66",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.83",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Пуэбло ВО 200 см",
    "UNI1": "1.50",
    "UNI2": "1.50",
    "UNI2спружиной": "1.13",
    "MINI/ROLLA19": "1.50",
    "AMG32": "5.14",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "1.56",
    "AMG45кассета": "4.97",
    "AMGДень-Ночь32": "5.14",
    "AMGДень-Ночь45": "3.76",
    "MGстальн.крон.": "4.68",
    "MGпласт.крон./монт.профиль": "5.04",
    "ROOF": "1.15"
  },
  {
    "name": "Рапсодия 200 см",
    "UNI1": "2.66",
    "UNI2": "2.66",
    "UNI2спружиной": "2.03",
    "MINI/ROLLA19": "2.66",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.83",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Руан 220 см",
    "UNI1": "3.54",
    "UNI2": "3.54",
    "UNI2спружиной": "2.71",
    "MINI/ROLLA19": "3.54",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "3.81",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Самира 240 см",
    "UNI1": "2.12",
    "UNI2": "2.12",
    "UNI2спружиной": "1.62",
    "MINI/ROLLA19": "2.12",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.24",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "5.31",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.64"
  },
  {
    "name": "Сатара 240 см",
    "UNI1": "1.56",
    "UNI2": "1.56",
    "UNI2спружиной": "–",
    "MINI/ROLLA19": "1.97",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.08",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "4.93",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "–"
  },
  {
    "name": "Сатин ВО 195 см",
    "UNI1": "2.94",
    "UNI2": "2.94",
    "UNI2спружиной": "2.25",
    "MINI/ROLLA19": "2.94",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "3.14",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Сафари 240 см",
    "UNI1": "2.84",
    "UNI2": "2.84",
    "UNI2спружиной": "2.17",
    "MINI/ROLLA19": "2.84",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "3.03",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Сиде 280 см",
    "UNI1": "1.88",
    "UNI2": "1.88",
    "UNI2спружиной": "1.43",
    "MINI/ROLLA19": "1.88",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "1.98",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "4.71",
    "MGстальн.крон.": "5.83",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.45"
  },
  {
    "name": "Сиде ВО 280 см",
    "UNI1": "1.30",
    "UNI2": "1.30",
    "UNI2спружиной": "0.98",
    "MINI/ROLLA19": "1.30",
    "AMG32": "4.48",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "1.34",
    "AMG45кассета": "4.33",
    "AMGДень-Ночь32": "4.48",
    "AMGДень-Ночь45": "3.27",
    "MGстальн.крон.": "4.09",
    "MGпласт.крон./монт.профиль": "4.41",
    "ROOF": "1.00"
  },
  {
    "name": "Силкскрин 240 см",
    "UNI1": "2.43",
    "UNI2": "2.43",
    "UNI2спружиной": "1.85",
    "MINI/ROLLA19": "2.43",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.58",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Сильвия 200 см",
    "UNI1": "4.04",
    "UNI2": "4.04",
    "UNI2спружиной": "3.10",
    "MINI/ROLLA19": "4.04",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "4.35",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Сказка 200 см",
    "UNI1": "2.84",
    "UNI2": "2.84",
    "UNI2спружиной": "2.17",
    "MINI/ROLLA19": "2.84",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "3.03",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Сканди 200 см",
    "UNI1": "2.50",
    "UNI2": "2.50",
    "UNI2спружиной": "1.91",
    "MINI/ROLLA19": "2.84",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "3.03",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Скрин 1% 300 см",
    "UNI1": "1.20",
    "UNI2": "1.20",
    "UNI2спружиной": "0.91",
    "MINI/ROLLA19": "1.20",
    "AMG32": "4.15",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "1.23",
    "AMG45кассета": "4.01",
    "AMGДень-Ночь32": "4.15",
    "AMGДень-Ночь45": "3.03",
    "MGстальн.крон.": "3.79",
    "MGпласт.крон./монт.профиль": "4.09",
    "ROOF": "0.92"
  },
  {
    "name": "Скрин 3% 300 см",
    "UNI1": "1.42",
    "UNI2": "1.42",
    "UNI2спружиной": "1.07",
    "MINI/ROLLA19": "1.42",
    "AMG32": "4.87",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "1.47",
    "AMG45кассета": "4.71",
    "AMGДень-Ночь32": "4.87",
    "AMGДень-Ночь45": "3.56",
    "MGстальн.крон.": "4.44",
    "MGпласт.крон./монт.профиль": "4.78",
    "ROOF": "1.09"
  },
  {
    "name": "Скрин 5% (0225, 1604,\r\n1608, 1882) 250, 300 см",
    "UNI1": "1.56",
    "UNI2": "1.56",
    "UNI2спружиной": "1.18",
    "MINI/ROLLA19": "1.56",
    "AMG32": "5.33",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "1.62",
    "AMG45кассета": "5.16",
    "AMGДень-Ночь32": "5.33",
    "AMGДень-Ночь45": "3.91",
    "MGстальн.крон.": "4.86",
    "MGпласт.крон./монт.профиль": "5.23",
    "ROOF": "1.19"
  },
  {
    "name": "Скрин 5% (1852 серый,\r\n2259 беж, 2406 т.беж)\r\n300 см",
    "UNI1": "1.11",
    "UNI2": "1.11",
    "UNI2спружиной": "0.84",
    "MINI/ROLLA19": "1.11",
    "AMG32": "3.86",
    "AMG45": "5.79",
    "AMG45+": "6.00",
    "AMG32кассета": "1.14",
    "AMG45кассета": "3.73",
    "AMGДень-Ночь32": "3.86",
    "AMGДень-Ночь45": "2.81",
    "MGстальн.крон.": "3.53",
    "MGпласт.крон./монт.профиль": "3.81",
    "ROOF": "0.85"
  },
  {
    "name": "Скрин 5% (1881 т.серый,\r\n1908 черный, 1608 св.серый,\r\n2261 св.беж) 300 см",
    "UNI1": "1.42",
    "UNI2": "1.42",
    "UNI2спружиной": "1.07",
    "MINI/ROLLA19": "1.42",
    "AMG32": "4.87",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "1.47",
    "AMG45кассета": "4.71",
    "AMGДень-Ночь32": "4.87",
    "AMGДень-Ночь45": "3.56",
    "MGстальн.крон.": "4.44",
    "MGпласт.крон./монт.профиль": "4.78",
    "ROOF": "1.09"
  },
  {
    "name": "Скрин 5% ALU 300 см",
    "UNI1": "1.47",
    "UNI2": "1.47",
    "UNI2спружиной": "1.11",
    "MINI/ROLLA19": "1.47",
    "AMG32": "5.05",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "1.53",
    "AMG45кассета": "4.88",
    "AMGДень-Ночь32": "5.05",
    "AMGДень-Ночь45": "3.69",
    "MGстальн.крон.": "4.60",
    "MGпласт.крон./монт.профиль": "4.95",
    "ROOF": "1.13"
  },
  {
    "name": "Соул 240 см",
    "UNI1": "2.58",
    "UNI2": "2.58",
    "UNI2спружиной": "1.97",
    "MINI/ROLLA19": "2.58",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.75",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "София 200 см",
    "UNI1": "2.24",
    "UNI2": "2.24",
    "UNI2спружиной": "1.70",
    "MINI/ROLLA19": "2.24",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.37",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "5.59",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Сфера ВО 220 см",
    "UNI1": "1.84",
    "UNI2": "1.84",
    "UNI2спружиной": "1.40",
    "MINI/ROLLA19": "1.84",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "1.93",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "4.60",
    "MGстальн.крон.": "5.70",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.41"
  },
  {
    "name": "Тальник 250 см",
    "UNI1": "2.30",
    "UNI2": "2.30",
    "UNI2спружиной": "1.75",
    "MINI/ROLLA19": "2.30",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.44",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "5.74",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Толедо 200 см",
    "UNI1": "2.24",
    "UNI2": "2.24",
    "UNI2спружиной": "1.70",
    "MINI/ROLLA19": "2.24",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.37",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "5.59",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Тропик 240 см",
    "UNI1": "3.54",
    "UNI2": "3.54",
    "UNI2спружиной": "2.71",
    "MINI/ROLLA19": "3.54",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "3.81",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Флора 200 см",
    "UNI1": "2.66",
    "UNI2": "2.66",
    "UNI2спружиной": "2.03",
    "MINI/ROLLA19": "2.66",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.83",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Флора ВО 200 см",
    "UNI1": "1.88",
    "UNI2": "1.88",
    "UNI2спружиной": "1.43",
    "MINI/ROLLA19": "1.88",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "1.98",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "4.71",
    "MGстальн.крон.": "5.83",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.45"
  },
  {
    "name": "Харизма 210 см",
    "UNI1": "2.18",
    "UNI2": "2.18",
    "UNI2спружиной": "1.66",
    "MINI/ROLLA19": "2.18",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.30",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "5.45",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.68"
  },
  {
    "name": "Шантунг 180 см",
    "UNI1": "1.80",
    "UNI2": "1.80",
    "UNI2спружиной": "1.37",
    "MINI/ROLLA19": "1.80",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "1.89",
    "AMG45кассета": "5.94",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "4.50",
    "MGстальн.крон.": "5.58",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.38"
  },
  {
    "name": "Шанхай 180 см",
    "UNI1": "1.47",
    "UNI2": "1.47",
    "UNI2спружиной": "–",
    "MINI/ROLLA19": "2.58",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.75",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "–"
  },
  {
    "name": "Шелк 200 см",
    "UNI1": "3.54",
    "UNI2": "3.54",
    "UNI2спружиной": "2.71",
    "MINI/ROLLA19": "3.54",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "3.81",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Шелк ВО 200 см",
    "UNI1": "1.42",
    "UNI2": "1.42",
    "UNI2спружиной": "1.07",
    "MINI/ROLLA19": "1.42",
    "AMG32": "4.87",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "1.47",
    "AMG45кассета": "4.71",
    "AMGДень-Ночь32": "4.87",
    "AMGДень-Ночь45": "3.56",
    "MGстальн.крон.": "4.44",
    "MGпласт.крон./монт.профиль": "4.78",
    "ROOF": "1.09"
  },
  {
    "name": "Шелк II 200 см",
    "UNI1": "2.50",
    "UNI2": "2.50",
    "UNI2спружиной": "1.91",
    "MINI/ROLLA19": "2.50",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.66",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Шикатан\r\nПуть самурая 180 см",
    "UNI1": "1.42",
    "UNI2": "1.42",
    "UNI2спружиной": "–",
    "MINI/ROLLA19": "1.59",
    "AMG32": "5.44",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "1.65",
    "AMG45кассета": "5.26",
    "AMGДень-Ночь32": "5.44",
    "AMGДень-Ночь45": "3.98",
    "MGстальн.крон.": "4.95",
    "MGпласт.крон./монт.профиль": "5.33",
    "ROOF": "–"
  },
  {
    "name": "Шикатан Чайная церемония 180 см",
    "UNI1": "1.35",
    "UNI2": "1.35",
    "UNI2спружиной": "–",
    "MINI/ROLLA19": "1.59",
    "AMG32": "5.44",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "1.65",
    "AMG45кассета": "5.26",
    "AMGДень-Ночь32": "5.44",
    "AMGДень-Ночь45": "3.98",
    "MGстальн.крон.": "4.95",
    "MGпласт.крон./монт.профиль": "5.33",
    "ROOF": "–"
  },
  {
    "name": "Эльба 220 см",
    "UNI1": "2.50",
    "UNI2": "2.50",
    "UNI2спружиной": "1.91",
    "MINI/ROLLA19": "2.50",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "2.66",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  },
  {
    "name": "Юта ВО 300 см",
    "UNI1": "1.62",
    "UNI2": "1.62",
    "UNI2спружиной": "1.23",
    "MINI/ROLLA19": "1.62",
    "AMG32": "5.54",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "1.69",
    "AMG45кассета": "5.36",
    "AMGДень-Ночь32": "5.54",
    "AMGДень-Ночь45": "4.06",
    "MGстальн.крон.": "5.04",
    "MGпласт.крон./монт.профиль": "5.43",
    "ROOF": "1.24"
  },
  {
    "name": "Ямайка 230 см",
    "UNI1": "2.36",
    "UNI2": "2.36",
    "UNI2спружиной": "1.80",
    "MINI/ROLLA19": "2.94",
    "AMG32": "6.00",
    "AMG45": "6.00",
    "AMG45+": "6.00",
    "AMG32кассета": "3.14",
    "AMG45кассета": "6.00",
    "AMGДень-Ночь32": "6.00",
    "AMGДень-Ночь45": "6.00",
    "MGстальн.крон.": "6.00",
    "MGпласт.крон./монт.профиль": "6.00",
    "ROOF": "1.70"
  }
]