import { useEffect, useRef, useState } from "react";
import moment from 'moment'
import { al } from "./uni/amigologo";
import ExcelJS from 'exceljs'
import { allrulonjson } from "./uni/allrulon"
import FileSaver from 'file-saver'
import { WidthHeight } from "./uni/WidthHeight"
import { maxHeights } from "./uni/maxHeights";
import { formatCurrency } from "./utilities/formatCurrency";
import './index.css'
import zebra_price from '../../jsons/zebra_price.json'
import zebra_tkani from '../../jsons/rulon_zebr_tkani.json'
import zebraall from '../../jsons/zebra_all.json'

export default function UniPlus() {

  const mat: any = useRef(null)
  const upr: any = useRef(null)

  const [obj, setObj] = useState(allrulonjson[1])
  const [w, setWidth] = useState(600)
  const [h, setHeight] = useState(1200)
  const [text, setText] = useState('АЖУР')
  const [clo, setClo] = useState('')
  const [u, setU] = useState('прав')
  const [radioUni, setRadioUni] = useState('2')
  const [arrColors, setArrColors] = useState<any>(<></>)
  const [kartinki, setKartinki] = useState<any>(<></>)
  const [miniprice, setMiniprice] = useState(0)
  const [uniprice, setUniprice] = useState(0)
  const [uni2price, setUni2price] = useState(0)
  const [uniprprice, setUniprprice] = useState(0)
  const [lvtprice, setLvtprice] = useState(0)
  const [num, setNum] = useState(1)
  const [currentColor, setCurrentColor] = useState(0)
  const [maxH, setMaxH] = useState(0)
  const dialogref: any = useRef(null)
  const dialogClose = () => dialogref.current.close()
  const dialogOpen = () => dialogref.current.showModal()
  const dialogColorsRef: any = useRef(null)
  const dialogColorsClose = () => dialogColorsRef.current.close()
  const dialogColorsOpen = () => dialogColorsRef.current.showModal()
  const [koefficient, setKoefficient] = useState(100)
  const [list, setList] = useState<any>([])
  const [changeSystem, setChangeSystem] = useState(true)

  const closeDialogVne = (e: any) => {
    const dialogDimensions = dialogColorsRef.current.getBoundingClientRect()
    if (
      e.clientX < dialogDimensions.left ||
      e.clientX > dialogDimensions.right ||
      e.clientY < dialogDimensions.top ||
      e.clientY > dialogDimensions.bottom
    ) {
      dialogColorsRef.current.close()
    }
  }

  const lvt: any = {
    "0": 32.96,
    "1": 34.76,
    "2": 36.55,
    "3": 38.34,
    "4": 40.14,
    "5": 41.93,
    "6": 43.72,
    "7": 45.52,
    "8": 47.31,
    "9": 49.11,
    "10": 50.9,
    "11": 52.69,
    "12": 54.49,
    "13": 56.28,
    "14": 58.07,
    "15": 59.87,
    "16": 61.66,
    "17": 63.45,
    "18": 65.25,
    "19": 67.04,
    "20": 68.84
  }
  const radioUniValue: any = {
    "1": ["МИНИ", 'Ширина по ткани (м)', 'Высота габаритная (м)', miniprice, "MINI/ROLLA19"],
    "2": ["УНИ-1", 'Ширина по ребру штапика UNI (м)', 'Высота по ребру штапика UNI (м)', uniprice, "UNI1"],
    "3": ["УНИ-2", 'Ширина по линии стыка штапика и рамы, UNI2 (м)', 'Высота по линии стыка штапика и рамы, UNI2 (м)', uni2price, "UNI2"],
    "4": ["УНИпр", 'Ширина по линии стыка штапика и рамы, UNI2 (м)', 'Высота по линии стыка штапика и рамы, UNI2 (м)', uniprprice, "UNI2спружиной"],
    "5": ["ЛВТ", 'Ширина по ткани (м)', 'Высота габаритная (м)', lvtprice, "AMG32"],
  }

  const onClo = () => {
    setClo(obj.color[0])
    setCurrentColor(0)
  }

  useEffect(() => {
    if (mat.current) {
      const name = allrulonjson[mat.current.options[mat.current.selectedIndex].value].name
      const findName = maxHeights.find((i: any) => i.name.toUpperCase().slice(0, i.name.indexOf(' ')) === name.toUpperCase().slice(0, i.name.indexOf(' ')))
      setMaxH(findName && +findName![radioUniValue[radioUni][4]] || 0)
    }
  }, [text, radioUni])

  const onText = () => {
    if (mat.current) {
      setText((changeSystem ? allrulonjson : zebra_price)[mat.current.options[mat.current.selectedIndex].value].name)
      onClo()
    }
  }

  useEffect(() => {
    setObj(mat.current && ((changeSystem ? allrulonjson : zebraall)[mat.current.options[mat.current.selectedIndex].value]))
  }, [mat.current && mat.current.options[mat.current.selectedIndex].value, changeSystem])

  useEffect(() => {
    setKartinki(
      <div className="d-flex col justify-content-center">
        <img className="m-2" key={0} src={obj.images.length > 0 && obj.images[currentColor][0] ? obj.images[currentColor][0] : 'img/noimage.jpg'} width={150} height={150} alt={clo} />
        <img className="m-2" key={1} src={obj.images.length > 0 && obj.images[currentColor][1] ? obj.images[currentColor][1] : 'img/noimage.jpg'} width={150} height={150} alt={clo} />
      </div>
    )
  }, [clo, changeSystem])

  useEffect(() => {
    setArrColors(obj.color.length > 0 ? obj.color.map((i: string, n: number) =>
      <button
        className={`d-flex m-2 mx-auto p-3 row w-75 justify-content-center rounded ${n === currentColor ? "border border-1 border-dark border-opacity-50" : "border border-1 border-light"}`}
        key={n}
        id={'' + n}
        onClick={() => {
          setClo(i)
          setCurrentColor(n)
          dialogColorsClose()
        }}
      >
        <img className="h-10 w-10 me-2" src={obj.images.length > 0 ? obj.images[n][1] : 'img/noimage.jpg'} alt="" />
        {i}</button>) : <div>бесцветный</div>)
  }, [obj, clo, changeSystem])

  useEffect(() => { obj && onClo() }, [obj, changeSystem])

  useEffect(() => {
    setMiniprice(Math.ceil((changeSystem ? obj : zebra_price[num]).mini! * w / 10) * num / 100 * koefficient)
    setUniprice(Math.ceil((changeSystem ? obj : zebra_price[num]).uni! * w / 10) * num / 100 * koefficient)
    setUni2price((Math.ceil((changeSystem ? obj : zebra_price[num]).uni! * w / 10) + 800) * num / 100 * koefficient)
    changeSystem && setUniprprice(Math.ceil(obj.unipr! * w / 10) * num / 100 * koefficient)
    setLvtprice((Math.ceil((changeSystem ? obj : zebra_price[num]).lvt * w / 10) + lvt[(w < 1000 ? 0 : Math.ceil((w - 1000) / 100)).toString()] * 100) * num / 100 * koefficient)
  }, [obj, w, h, num, koefficient, changeSystem])

  const onchanupr = () => {
    if (upr.current) {
      setU(upr.current.options[upr.current.selectedIndex].text)
    }
  }

  const go = async () => {
    let date = 'Дата ' + moment().format("DD") + '.' + moment().format("MM") + '.' + moment().format("YYYY") + 'г.'
    let dt = moment().format("DD") + moment().format("MM") + moment().format("YY")
    const workbook = new ExcelJS.Workbook();
    const wsh = workbook.addWorksheet(radioUniValue[radioUni][0])
    wsh.getRow(3).height = 35
    wsh.getCell('A3').value = 'Название фирмы "ГерАрт"'
    wsh.getCell('A3').font = {
      name: 'Times New Roman',
      size: 16
    }
    wsh.getCell('C3').value = date
    wsh.getCell('C3').font = {
      name: 'Times New Roman',
      size: 16
    }
    const colu = [
      { name: 'Вид изделия' },
      { name: 'Наименование ткани' },
      { name: 'Цвет ткани' },
      { name: radioUniValue[radioUni][1] },
      { name: radioUniValue[radioUni][2] },
      { name: 'Кол-во\n(шт.)' },
      { name: 'Управление\n(прав / лев)' },
      { name: 'Длина\nуправления\n(м)' },
      { name: 'Цвет\nфурнитуры' },
      { name: 'Со свер-\nлением' },
      { name: 'На скотч' },
      { name: 'Натяжитель\nцепи' },
    ]
    const massA = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L']
    const widthCol = [23, 32, 27, 12, 12, 12, 12, 12, 12, 12, 12, 12]

    wsh.getRow(5).height = 155
    wsh.getRow(5).font = { name: 'Times New Roman', size: 11 };
    wsh.getRow(4).font = { name: 'Times New Roman', size: 11 };
    wsh.mergeCells('D4:E4');
    wsh.mergeCells('J4:K4');
    wsh.mergeCells('A4:A5');
    wsh.mergeCells('B4:B5');
    wsh.mergeCells('C4:C5');
    wsh.mergeCells('F4:F5');
    wsh.mergeCells('G4:G5');
    wsh.mergeCells('H4:H5');
    wsh.mergeCells('I4:I5');
    wsh.mergeCells('L4:L5');
    wsh.getCell('D4').value = radioUniValue[radioUni][0]
    wsh.getCell('D4').font = {
      name: 'Times New Roman',
      size: 14,
      bold: true
    };
    wsh.getCell('D4').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    wsh.getCell('D4').border = {
      top: { style: 'medium' },
      left: { style: 'medium' },
      bottom: { style: 'medium' },
      right: { style: 'medium' }
    }
    wsh.getCell('D4').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'dbeef4' },
    }
    wsh.getCell('J4').value = 'Тип установки'
    wsh.getCell('J4').font = {
      name: 'Times New Roman',
      size: 11
    };
    wsh.getCell('J4').alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
    wsh.getCell('J4').border = {
      top: { style: 'medium' },
      left: { style: 'medium' },
      bottom: { style: 'medium' },
      right: { style: 'medium' }
    }
    wsh.getCell('J4').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'dbeef4' },
    }


    for (let i = 0; i < 12; i++) {
      let col = massA[i]
      let c = col + 5
      let w = widthCol[i]
      wsh.getColumn(col).width = w
      wsh.getCell(c).alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
      wsh.getCell(c).border = {
        top: { style: 'medium' },
        left: { style: 'thin' },
        bottom: { style: 'medium' },
        right: { style: 'thin' }
      }
      wsh.getCell(c).value = colu[i].name
      wsh.getCell(c).fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: 'dbeef4' },
      }
    }

    wsh.getCell('A4').border = {
      left: { style: 'medium' },
      top: { style: 'medium' },
      bottom: { style: 'medium' },
    }
    wsh.getCell('L4').border = {
      right: { style: 'medium' },
      top: { style: 'medium' },
      bottom: { style: 'medium' },
    }

    wsh.getCell('A1').value = 'Бланк заказа на кассетные рулонные шторы UNI1, UNI2, UNI1-Зебра, UNI2-Зебра, UNI с пружиной'
    wsh.getCell('A1').alignment = { horizontal: 'right' }
    wsh.getCell('A1').font = { name: 'Times New Roman', size: 14, bold: true }
    wsh.mergeCells('A1:L1')

    list.map((item: any, i: number) => {
      let rw = 6 + i
      wsh.spliceRows(rw, 0, [item.system, item.name, item.color, item.width / 1000, item.height / 1000, item.kolvo, item.upravlenie, "ст", "бел", "да"])
      wsh.getRow(rw).alignment = { vertical: 'middle', horizontal: 'center' }
      wsh.getRow(rw).font = { name: 'Times New Roman', size: 14 }
      for (let j = 0; j < 12; j++) {
        let col = massA[j]
        let c = col + rw
        wsh.getCell(c).border = { left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } }
      }
      wsh.getRow(rw).height = 58
      wsh.getRow(rw + 1).height = 43

      wsh.getCell('D' + rw).numFmt = '0.000'
      wsh.getCell('E' + rw).numFmt = '0.000'

      wsh.getCell('A' + (rw + 1)).value = '   Подпись___________                             Печать__________                                 Оплату гарантируем_____________                    С техническими особенностями ознакомлены___________'

    })
    const myBase64Image = "data:image/png;base64," + al
    const imageId2 = workbook.addImage({
      base64: myBase64Image,
      extension: 'png',
    })
    wsh.addImage(imageId2, 'J' + (8 + list.length + 1) + ':K' + (11 + list.length + 1));

    const buffer = await workbook.xlsx.writeBuffer();
    FileSaver.saveAs(new Blob([buffer]), `Заявка_${radioUniValue[radioUni][0]}_${dt}.xlsx`)
  }

  const [summa, setSumma] = useState(0)

  const add = () => {
    setList([...list,
    {
      "id": Math.random() * 1000000,
      "delet": delet,
      "system": radioUniValue[radioUni][0],
      "name": text,
      "color": clo,
      "width": w,
      "height": h,
      "kolvo": num,
      "upravlenie": u,
      "image": obj.images.length > 0 && obj.images[currentColor][1] ? obj.images[currentColor][1] : 'img/noimage.jpg',
      "cena": radioUniValue[radioUni][3]
    }])
    setSumma(summa + radioUniValue[radioUni][3])
  }

  const classNameInput = "d-block w-100 rounded border border-1 pt-4 px-2 py-2 text-dark"
  const delet = (id: string) => {
    setList([...list.filter((item: any) => item.id !== id)])
    setSumma(summa - list.find((item: any) => item.id == id).cena)
  }

  const createJsonWithoutAmigo = () => {
    // const zebrmap = zebra_tkani.map(i => `{
    //   "name":"${i.name}",
    //     "color":"${i.color}",
    //     "photo1":"/imgs/rulonka_zebra_tkani/${i.photo1.slice(40, i.photo1.length)}",
    //     "photo2":"/imgs/rulonka_zebra_tkani/${i.photo2.slice(40, i.photo2.length)}"
    // }`
    // )

    // const blob = new Blob([`[${zebrmap}]`], { type: "text/plain;charset=utf-8" });
    // FileSaver.saveAs(blob, "rulon_zebr_tkani.json");
    let zebrmap: any = []
    let colorsArrZ: any = []
    let imagesArrZ: any = []
    let currentName = zebra_tkani[0].name
    let newObj = {
      "name": "name",
      "maxWidth": 0,
      "lvt": 0,
      "mini": 0,
      "uni": 0,
      "uni2": 0,
      "color": [],
      "images": []
    }

    zebra_tkani.map((i, n) => {
      const currentPriceObj = zebra_price.find(item => item.name === currentName.split(' ')[1])
      if (i.name.split(' ')[1] === currentName.split(' ')[1]) {
        colorsArrZ = [...colorsArrZ, i.color]
        imagesArrZ = [...imagesArrZ, [i.photo1, i.photo2]]
        if (currentPriceObj) {
          newObj.name = currentPriceObj.name
          newObj.uni = currentPriceObj.uni
          newObj.mini = currentPriceObj.mini
          newObj.uni2 = currentPriceObj.uni2
          newObj.lvt = currentPriceObj.lvt
          newObj.maxWidth = currentPriceObj.maxWidth
          newObj.color = colorsArrZ
          newObj.images = imagesArrZ
        }
      } else {
        zebrmap = [...zebrmap, JSON.stringify(newObj)]
        //   zebrmap.push(`{
        //     "name": "${currentPriceObj?.name}",
        //     "maxWidth": ${currentPriceObj?.maxWidth},
        //     "lvt": ${currentPriceObj?.lvt},
        //     "mini": ${currentPriceObj?.mini},
        //     "uni": ${currentPriceObj?.uni},
        //     "uni2": ${currentPriceObj?.uni2},
        //     "color": [${colorsArrZ}],
        //     "images": [${imagesArrZ}]
        // }`)
        if (n < zebra_tkani.length - 1) currentName = zebra_tkani[n + 1].name
        colorsArrZ = []
        imagesArrZ = []
      }

    })

    const zebrmapOld = zebra_price.map(i => `{
        "name": "${i.name}",
        "maxWidth": ${i.maxWidth},
        "lvt": ${i.lvt},
        "mini": ${i.mini},
        "uni": ${i.uni},
        "uni2": ${i.uni2},
        "color": [${colorsArrZ}],
        "images": [${imagesArrZ}]
    }`
    )

    const blob = new Blob([`[${zebrmap}]`], { type: "text/plain;charset=utf-8" });
    FileSaver.saveAs(blob, "zebra_all.json");
    // console.log(`[${zebrmap}]`)
  }

  const systemSet = () => {
    setChangeSystem(!changeSystem)
  }

  return (
    <div className=" mt-3">
      <div className="d-flex justify-content-center">
        <button type="button" className="btn btn-outline-primary mb-3" onClick={systemSet}>{changeSystem ? "рулонные шторы" : "шторы зебра"}</button>
      </div>

      <div className="d-flex flex-row justify-content-around" onChange={(e) => setRadioUni((e.target as HTMLTextAreaElement).value)}>
        <div className="d-flex flex-column">
          <input type="radio" id="MINI" name="uni" value='1' />
          <label className="ms-2" htmlFor="MINI">MINI</label>
        </div>
        <div className="d-flex flex-column">
          <input type="radio" id="UNI-1" name="uni" value='2' defaultChecked />
          <label className="ms-2" htmlFor="UNI-1">UNI-1</label>
        </div>
        <div className="d-flex flex-column">
          <input type="radio" id="UNI-2" name="uni" value='3' />
          <label className="ms-2" htmlFor="UNI-2">UNI-2</label>
        </div>
        {changeSystem && <div className="d-flex flex-column">
          <input type="radio" id="UNIpr" name="uni" value='4' />
          <label className="ms-2" htmlFor="UNIpr">UNIpr</label>
        </div>}
        <div className="d-flex flex-column">
          <input type="radio" id="LVT" name="uni" value='5' />
          <label className="ms-2" htmlFor="LVT">LVT</label>
        </div>
      </div>

      <div className='d-flex flex-row justify-content-around'>
        <WidthHeight toApp={(e) => setWidth(e)} name={"width"} label={"ширина"} labelClassNames={[]} state={600} />
        <WidthHeight toApp={(e) => setHeight(e)} name={"height"} label={`высота max:${+maxH * 1000}`} labelClassNames={[h < maxH * 1000 ? '' : 'text-danger']} state={1200} />
      </div>

      <div className='d-flex flex-row justify-content-around'>
        <div className="col position-relative w-100 m-2">
          <select id='mat' className={classNameInput} defaultValue={1} onChange={onText} ref={mat}>
            {(changeSystem ? allrulonjson : zebraall).map((i: any, n: number) => <option key={n} value={n} >{i.name}</option>)}
          </select>
          <label className="label" htmlFor="mat">материал</label>
        </div>
        <div className="col position-relative w-100 m-2 d-flex">
          <button
            className={`${classNameInput} bg-white`}
            onClick={dialogColorsOpen}
          >{clo}</button>
          <label className="label" htmlFor="col">цвет</label>
          <dialog
            className="pe-6 ps-2 overflow-x-hidden py-4 rounded border border-success border-opacity-25 "
            ref={dialogColorsRef}
            onClick={closeDialogVne}
            style={{ width: '330px' }}
          >
            {arrColors}
          </dialog>
        </div>
      </div>

      <div className='d-flex flex-row justify-content-around'>
        <div className="position-relative w-100 m-2 d-flex col">
          <div className="btn-group" role="group" aria-label="Basic example">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => { const currentnum = num - 1; num > 1 && setNum(currentnum) }}>-</button>
            <input
              className="w-100 border border-1 px-3 text-center"
              value={num}
              onChange={(e) => setNum(+(e.target as HTMLInputElement).value)}
              type="number"
            />
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => { const currentnum = num + 1; num > 0 && setNum(currentnum) }}>+</button>
          </div>
          {/* <label className="label">кол-во, шт</label> */}
        </div>

        <div className="col position-relative w-100 m-2 d-flex">
          <select
            name="upr"
            id='upr'
            className={classNameInput}
            defaultValue="прав"
            onChange={onchanupr}
            ref={upr}>
            <option value="прав">прав</option>
            <option value="лев">лев</option>
            <option value="лев">лев/прав</option>
          </select>
          <label className="label" htmlFor="upr">управление</label>
        </div>

      </div>

      <div className='d-flex flex-row justify-content-around my-2'>
        {(w > 350 && w < 6000 && h > 350 && h < 6000) &&
          <button
            id="add"
            className="btn btn-primary"
            onClick={add}>++</button>}
        {(list.length > 0) &&
          <button
            id="xlsx"
            className="btn btn-primary"
            onClick={go}>xlsx</button>}

        <button
          onClick={dialogOpen}
          className="btn btn-primary">
          {formatCurrency(radioUniValue[radioUni][3])}
        </button>
        <dialog className="p-3 overflow-x-hidden" ref={dialogref}>

          <img onClick={dialogClose} width={40} height={40} src='img/ok.svg' className="mb-3" />

          <div className="col position-relative w-100 d-flex">
            <input
              className={`${classNameInput} text-center display-4`}
              defaultValue={koefficient}
              onChange={(e) => setKoefficient(+(e.target as HTMLInputElement).value)}
              type="number"
            />
            <label className="label">коэфф</label>
          </div>
        </dialog>

        {(list.length > 0) &&
          <div className="btn btn-primary ">
            {formatCurrency(summa)}
          </div>}
      </div>

      {list.length > 0 && <ul role="list" className="list-group">
        {list.map((l: any, n: number) => (
          <li key={n} className="list-group-item d-flex col px-1">
            <div className=" row d-flex">
              <div className="d-flex col">
                <img width={30} height={30} className="rounded-circle object-fit-contain m-0" src={list ? l.image : "img/noimage.jpg"} alt="" />
                <h6 className="m-1">{list && l.name}</h6>
              </div>
              <small className="m-1">{`${list && l.color} ш${list && l.width}*в${list && l.height} ${list && l.system} ${list && l.kolvo}шт`}</small>
            </div>
            <div className="m-1 text-center">
              <h6 className="text-body-secondary">{list && formatCurrency(l.cena)}</h6>
              <img
                width={30} height={30}
                onClick={() => delet(l.id)}
                src='img/delete.svg' alt="" />
            </div>


          </li>
        ))}
      </ul>}
      <div>
        {kartinki}
      </div>
      {/* <button onClick={createJsonWithoutAmigo}>
        json
      </button> */}


    </div >

  )

}