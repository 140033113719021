import { FC, useEffect, useState } from "react"
import { Form } from "react-bootstrap"
import price from '../../jsons/alutech_price_trend.json'
import { formatCurrency } from "../../utilities/formatCurrency"

export const SV_trend_calc = () => {

  const [width, setWidth] = useState(2500)
  const [height, setHeight] = useState(2000)
  const [itog, setItog] = useState(0)
  const maxW = 6000
  const maxH = 3250

  const inputWidth = (event: any) => {
    let e = (event.target.value > maxW) ? maxW : event.target.value
    setWidth(e)
  }

  const inputHeight = (event: any) => {
    let e = (event.target.value > maxH) ? maxH : event.target.value
    setHeight(e)
  }

  const raschet = () => {
    const height_stroka: any = price.find(i => i.height >= height)
    const height_arr = Object.keys(height_stroka)
    const peresechenie: any = height_arr.find(i => +i >= width)
    const znachenie = height_stroka[peresechenie]
    setItog(znachenie * 2.1)
  }

  useEffect(() => {
    raschet()
  }, [width, height])

  return (
    <>
      <div className="row mb-3">

        <Form.Floating className="col">
          <Form.Control
            size="lg"
            type="number"
            placeholder="введите ширину в мм"
            min={1750}
            max={maxW}
            maxLength={4}
            required
            onChange={inputWidth}
            onInput={inputWidth}
            value={width}
          // onClick={() => setWidth(0)}
          />
          <label htmlFor="sv_width">↔ ширина, мм</label>
          <Form.Control.Feedback type="invalid">
            Введите ширину в миллиметрах от 1750 мм до {maxW} мм
          </Form.Control.Feedback>
        </Form.Floating>

        <Form.Floating className="col">
          <Form.Control
            size="lg"
            type="number"
            placeholder="введите высоту в мм"
            min={1750}
            max={maxH}
            maxLength={4}
            required
            onChange={inputHeight}
            onInput={inputHeight}
            value={height}
          // onClick={() => setHeight(0)}
          />
          <label htmlFor="sv_height">↕ высота, мм</label>
          <Form.Control.Feedback type="invalid">
            Введите высоту в миллиметрах от 300мм до {maxH}мм
          </Form.Control.Feedback>
        </Form.Floating>
      </div>

      <p className="h4">{height >= 1750 && width >= 1750 ? `Стоимость: ${formatCurrency(itog)}` : "введите корректные данные"}</p>
    </>
  )
}