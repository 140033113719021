import { Button, ButtonToolbar, Nav } from "react-bootstrap"
import { MasonryUniversal } from "../../../Masonry_universal"
import { ModalBootstrap } from "../../../ModalBootstrap"
import './PlisseAccordion.css'
import { ReactComponent as Photo } from '../img/photo.svg'
import { ReactComponent as System } from '../img/system.svg'
import { ReactComponent as Material } from '../img/material.svg'
import { ReactComponent as Calc } from '../img/calc.svg'
import { useLocation } from "react-router-dom"
import { links } from "../../../../links"
import { ContactsAllInOne } from "../../../Header/ContactsAllInOne"

export const PlisseAccordion = () => {

  let location = useLocation()

  const podrobnee = (location.pathname === '/') ?
    <div className="d-grid gap-2 mt-2">
      <Button
        variant="outline-primary"
        href={links[3]}
      >...подробнее</Button>
    </div> : <></>

  const modal_plisse_img = <ModalBootstrap
    title=""
    variant={"outline-primary"}
    btn_txt={
      <>
        <Nav.Item>
          <Photo />
        </Nav.Item><Nav.Item>
          <span className="span_text">фото</span>
        </Nav.Item>
      </>
    }
    children={
      <MasonryUniversal
        id={'plisse'}
        path_images={'./plisse/plisse'}
        n={12} />
    } />

  const contacts = (location.pathname === '/') ? <></> : <ContactsAllInOne />

  return (
    <>
      <ButtonToolbar
        className="justify-content-between"
        aria-label="Toolbar with Button groups">

        {modal_plisse_img}

        <Button variant="outline-primary">
          <Nav.Item>
            <System />
          </Nav.Item>
          <Nav.Item>
            <span className="span_text">системы</span>
          </Nav.Item>
        </Button>

        <Button variant="outline-primary">
          <Nav.Item>
            <Material />
          </Nav.Item>
          <Nav.Item>
            <span className="span_text">материал</span>
          </Nav.Item>
        </Button>

        <Button variant="outline-primary">
          <Nav.Item>
            <Calc />
          </Nav.Item>
          <Nav.Item>
            <span className="span_text">расчёт</span>
          </Nav.Item>
        </Button>

        {contacts}

      </ButtonToolbar>

      {podrobnee}

    </>
  )
}