import './sangrid.css'
import { useEffect, useState } from 'react'
import { formatCurrency } from '../../utilities/formatCurrency'
import { Cards } from '../Cards'
import { SendTg } from '../SendTg'
import { getImageSize } from 'react-image-size';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { CalcRollsPd45 } from '../Calc_rolls_pd45'
import { Carousel } from 'react-bootstrap'

export const SantehRolls = () => {

  const [arrItems, setArrItems] = useState<any>()
  const [roll_card, setRoll_card] = useState(<></>)
  // const [imgsCard, setImgsCard] = useState<any>([])
  const [filterColor, setFilterColor] = useState('')
  const [filterKorob, setFilterKorob] = useState('')
  const [imagesSizes, setImagesSizes] = useState<any>([])

  interface OutJsonProps {
    name: string;
    description: string;
    width: number;
    height: number;
    price: number;
    color: string;
    korob: string;
    photo: string;
    createdAt: string;
    updatedAt: string
  }
  const [outJson, setOutJson] = useState<OutJsonProps[]>()


  // async function fetchImageSize(url: string) {
  //   try {
  //     const dimensions = await getImageSize(`https://gerart.spb.ru${url}`);
  //     setImagesSizes([...imagesSizes, dimensions]);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }

  // const windowwidth = window.innerWidth

  // async function dimentions(i: any) {
  //   const dim = await getImageSize(`https://gerart.spb.ru${i.photo}`)
  //   const heightGrid = windowwidth >= 500 ? Math.ceil(dim.height / dim.width * 32) + 37 : Math.ceil(dim.height / dim.width * 15) + 29
  //   return heightGrid
  // }

  // async function uniSetArrImages(jsondata: any) {
  //   jsondata.map((i: any) => {
  //     const size = dimentions(i)
  //     setImagesSizes([...imagesSizes, size])
  //   })
  // }

  const uniSetArrItems = (jsondata: OutJsonProps[]) => {
    setArrItems(jsondata.map((item: any, num: number) => {
      const h_text = window.innerWidth >= 500 ? "h4" : "h6"
      // const dim = await getImageSize(`https://gerart.spb.ru${item.photo}`)
      // const heightGrid = windowwidth >= 500 ? Math.ceil(dim.height / dim.width * 32) + 37 : Math.ceil(dim.height / dim.width * 15) + 29
      const arrPhoto = item.photo.split(',')
      const countPhoto = arrPhoto.length

      return <div key={num} className="pin">
        {/* return <div key={num} className="pin" style={{ gridRowEnd: `span ${heightGrid ? heightGrid : 80}` }}> */}
        {
          countPhoto < 2 ? <img src={item.photo} alt={item.name} loading="lazy" placeholder={item.name} className="rounded d-block w-100" /> :

            <Carousel slide={false} touch>
              {arrPhoto.map((i: string) => (
                <Carousel.Item>
                  <img
                    className="d-block w-100 rounded"
                    alt={item.name}
                    src={i}
                    key={i}
                  />
                </Carousel.Item>
              ))}
            </Carousel>
        }
        {/* {countPhoto < 2 && <img src={item.photo} alt={item.name} loading="lazy" placeholder={item.name} className="rounded" />} */}
        <p className={`${h_text} text-wrap nametxt`}>{item.name}</p>
        <p className={`${h_text} text-wrap nametxt text-secondary`}>ш{item.width}*в{item.height}</p>
        {/* <p className={`${h_text} text-wrap nametxt `}>{formatCurrency(+item.price)}</p> */}
        <p className={`${h_text} text-wrap nametxt `}><CalcRollsPd45 width={item.width} height={item.height} /></p>
        <p className={h_text}><small className='descriptiontxt text-secondary'>{item.description}</small></p>
        <button
          type="button"
          className="btn btn-secondary btn-sm"
          onClick={() => openCard(item)}
        >подробнее...</button>
      </div>
    }))
  }

  const fetchData = async () => {
    const response = await fetch('/jsons/santehrolls.json');
    const jsonData = await response.json();
    setOutJson(jsonData);
    // uniSetArrImages(jsonData)
  }

  useEffect(() => {
    fetchData();
  }, []);



  // useEffect(() => {
  //   setImgsCard(outJson && outJson.map((i: any) => {
  //     const heightGrid = window.innerWidth >= 500 ? Math.ceil(dimensions.height / dimensions.width * 32) + 37 : Math.ceil(dimensions.height / dimensions.width * 15) + 29
  //     return heightGrid
  //   }))
  // }, [outJson, imagesSizes])

  const openCard = (item: any) => {
    window.scroll({
      top: 400,
      behavior: "smooth"
    })
    const arrPhoto = item.photo.split(',')
    const countPhoto = arrPhoto.length
    setRoll_card(<Cards
      h2={item.name}
      h2_muted={`ш${item.width}*в${item.height}`}
      text={<>
        <div className='mb-2'></div>
        {/* <h2>{formatCurrency(+item.price)}</h2> */}
        <h2><CalcRollsPd45 width={item.width} height={item.height} /></h2>
        {item.description}
        <div className='mb-2'></div>
        {/* <SendTg text={`ш${item.width}*в${item.height} цена:${formatCurrency(+item.price)}`} photo={"http://gerart.spb.ru" + item.photo} btnname={'заказать'} /> */}
        <SendTg text={`ш${item.width}*в${item.height} цена:${<CalcRollsPd45 width={item.width} height={item.height} />}`} photo={"http://gerart.spb.ru" + item.photo} btnname={'заказать'} />
        <div className='mb-2'></div>
      </>}
      src={countPhoto < 2 && item.photo}
      child={countPhoto > 1 &&
        <Carousel slide={false} touch>
          {arrPhoto.map((i: string) => (
            <Carousel.Item>
              <img
                className="d-block w-100 rounded"
                alt={item.name}
                src={i}
                key={i}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      }

      right={true} />)
  }

  const getBlocks = () => {
    if (outJson) {
      const filtermassivkor = filterKorob !== "" ? outJson.filter((i: any) => i.korob === filterKorob) : outJson
      const filtermassiv = filterColor !== "" ? filtermassivkor.filter((i: any) => i.color === filterColor) : filtermassivkor
      uniSetArrItems(filtermassiv)
    }
  }

  // useEffect(() => {
  //   outJson && dimentions(outJson)
  // }, [outJson])

  useEffect(() => {
    getBlocks()
  }, [filterKorob, filterColor, imagesSizes, outJson])

  return (
    <>
      <div className='d-flex flex-row'>
        <div className="form-floating m-2">
          <select className="form-select" id="filterColor" aria-label="filterColor" onChange={(e) => setFilterColor(e.currentTarget.value)}>
            <option value="" >все</option>
            <option value="белый" >белый</option>
            <option value="бежевый" >бежевый</option>
            <option value="серый" >серый</option>
            <option value="серебристый металлик" >серебристый металлик</option>
            <option value="коричневый" >коричневый</option>
          </select>
          <label htmlFor="filterColor" className='p-2 fs-6'>цвет</label>
        </div>
        <div className="form-floating m-2">
          <select className="form-select" id="filterKorob" aria-label="filterKorob" onChange={(e) => setFilterKorob(e.currentTarget.value)}>
            <option value="" >все</option>
            <option value="out" >наружу</option>
            <option value="in" >внутрь</option>
          </select>
          <label htmlFor="filterKorob" className='p-2 fs-6'>короб</label>
        </div>
      </div>
      <div>{roll_card}</div>
      {/* <div className="pin_container">
        {arrItems}
      </div> */}
      {/* {window.innerWidth} */}
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 200: 1, 355: 2, 900: 3 }}
      >
        <Masonry>
          {arrItems}
        </Masonry>
      </ResponsiveMasonry>
    </>
  )
}