import { Button } from "react-bootstrap"
import { useShoppingCart } from "../ShopingCart/context/ShopingCartContext"

export type AddToCartProps = {
  id: number
}

type AddToCartThisProps = {
  classname?: string
}

type AllProps = AddToCartProps & AddToCartThisProps

export const AddToCart = ({ id, classname }: AllProps) => {
  const { getItemQuantity, increaseCartQuantity, decreaseCartQuantity, removeFromCart } = useShoppingCart()
  const quantity = getItemQuantity(id)
  return(
    <>
    {quantity === 0 ? (
            <Button
              className={`w-100 ${classname}`}
              onClick={() => increaseCartQuantity(id)}
            >+ Добавить</Button>
          ) : <div
            className="d-flex align-items-center flex-column"
            style={{ gap: '.5rem' }}
          >
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ gap: '.5rem' }}
            >
              <Button onClick={() => decreaseCartQuantity(id)}>-</Button>
              <div>
                <span className="fs-3">{quantity}</span>
                в корзине
              </div>
              <Button onClick={() => increaseCartQuantity(id)}>+</Button>
            </div>
            <Button onClick={() => removeFromCart(id)} variant='danger' size="sm">Удалить</Button>
          </div>}
    </>
  )
}