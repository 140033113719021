import { Offcanvas, Stack } from "react-bootstrap"
import { useShoppingCart } from "../context/ShopingCartContext"
import { formatCurrency } from "../utilities/formatCurrency"
import { CartItem } from "./CartItem"
import { allItems } from "../data/allItems"

type ShopingCartProps = {
  isOpen: boolean
}

export const ShopingCart = ({ isOpen }: ShopingCartProps) => {
 
  const { closeCart, cartItems } = useShoppingCart()

  
  return (
    <Offcanvas show={isOpen} onHide={closeCart} placement='end' style={{zIndex:'1055'}}>
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Корзина покупок</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <Stack gap={3}>
          {cartItems.map(item => (
            <CartItem key={item.id} {...item} />
          ))} 
          <div className="ms-auto fw-bold fs-5">
            Итого: {formatCurrency(cartItems.reduce((total, cartItem) => {
              const item = allItems.find(i => i.id === cartItem.id)
              return total + (item?.price || 0) * cartItem.quantity
            }, 0)
            )}
          </div>
        </Stack>
      </Offcanvas.Body>
    </Offcanvas>
  )
}