export const profilObj: any = {
  39: [53, 2150, 4017,
    {
      1560: 137,
      1989: 150,
      2418: 165,
      2964: 180,
      4017: 205,
      6000: 250
    }
  ],
  44: [60, 3230, 5240,
    {
      1020: 150,
      1410: 165,
      2200: 180,
      3260: 205,
      4356: 230,
      6000: 250
    }
  ],
  55: [65, 3130, 4730,
    {
      1045: 165,
      1815: 180,
      2365: 205,
      3520: 230,
      6000: 250
    }
  ],
  56: [75, 4000, 6000,
    {
      2184: 180,
      2856: 205,
      4648: 250,
      6000: 300
    }
  ],
  77: [90, 7000, 6000,
    {
      1925: 250,
      2772: 300,
      4543: 360,
      6000: 405
    }
  ]
}