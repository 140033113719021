import { FC, useContext, useEffect, useState } from "react"
import { Form, Row, Col, Button, Accordion, useAccordionButton, ButtonToolbar, Table, ListGroup } from "react-bootstrap"
import { Context } from "../../../context"
import { useAppDispatch, useAppSelector } from "../../../UlbiTV/hooks/redux"
import { userSlice } from "../../../UlbiTV/store/reducers/UserSlice"
import { formatCurrency } from "../../../utilities/formatCurrency"
import { Basket } from "../../Header/Basket"
import { InputHeight } from "../../InputHeight"
import { InputWidth } from "../../InputWidth"
import { SelectMultiselect, SelectOption } from "../../SelectMultiselect"
import { spisokVert } from "./spisokVert"

const options = [
  { label: 'замер', value: 1 },
  { label: 'доставка', value: 2 },
  { label: 'монтаж', value: 3 },
  { label: 'сложность', value: 4 },
  { label: 'ночь', value: 5 },
]

export const CalcVertical = () => {

  const { counts, setCounts, addBasket } = useContext<any>(Context)

  const [width, setWidth] = useState(1200)
  const [height, setHeight] = useState(1200)
  const [material, setMaterial] = useState('Аргос')
  const [result, setResult] = useState(0)
  const [spisokIzdeliy, setSpisokIzdeliy] = useState<any>([])

  const { count } = useAppSelector(state => state.userReducer)
  const { increment } = userSlice.actions
  const { decrement } = userSlice.actions
  const dispatch = useAppDispatch()
  //console.log(increment(counts))

  let massivBattons: any[] = []

  interface Btn {
    children: any
    eventKey: string
  }

  const CustomToggle: FC<Btn> = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionButton(eventKey)
    return (
      <Button
        className="m-1"
        variant={"outline-primary"}
        onClick={decoratedOnClick}
      >
        {children}
      </Button>
    )
  }

  for (let ky of Object.keys(spisokVert)) {
    massivBattons = [...massivBattons,
    <div className="d-grid gap-2" key={ky} onClick={() => setMaterial(ky)}>
      <CustomToggle eventKey='100'>
        {ky}
      </CustomToggle>
    </div>
    ]
  }

  useEffect(() => {
    setResult(Math.ceil(width * height / 1000000 * spisokVert[material]) * 80 * 1.5)
  }, [width, height, material])

  const basketItogo = (count > 0) ? <Basket count={count} onclick={undefined} /> : <></>

  const addIzdelie = (width: any, height: any, material: any, result: any) => {
    setSpisokIzdeliy([...spisokIzdeliy, {
      width,
      height,
      material,
      result,
      id: new Date().toISOString()
    }
    ])
    dispatch(increment(1))
  }

  const [spisokMap, setSpisokMap] = useState<any>([])

  useEffect(() => {
    setSpisokMap(spisokIzdeliy.map((item: any, index: any) =>
      <ListGroup.Item action variant="success" key={item.id} onClick={() => deleteIzdelie(item.id)}>
        {index + 1}. Жалюзи вертикальные, ширина: {item.width}, высота: {item.height}, материал: {item.material}, стоимость: {item.result} руб.
      </ListGroup.Item>
    ))
  }, [spisokIzdeliy])

  const deleteIzdelie = (id: string) => {
    setSpisokIzdeliy([...spisokIzdeliy.filter((elem: any) => elem.id !== id)])
    dispatch(decrement(1))
  }

  const [value1, setValue1] = useState<SelectOption[]>([options[0]])

  return (
    <>
      <Form validated>
        <Row>
          <Col>
            <InputWidth onChange={(txt) => setWidth(txt)} max={6000} />
          </Col>
          <Col>
            <InputHeight onChange={(txt) => setHeight(txt)} max={6000} />
          </Col>
        </Row>
      </Form>
      <Accordion>
        <Accordion.Item className="m-1" eventKey="100">
          <Accordion.Header>{material}</Accordion.Header>
          <Accordion.Body>
            {massivBattons}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <SelectMultiselect classnames={'my-2 w-100'} multiple options={options} value={value1} onChange={o => setValue1(o)} />
      <ButtonToolbar
        className="justify-content-between mt-2 px-3"
        aria-label="Блок добавления изделий в список">
        <Button className="m-1" variant={"success"}>
          {formatCurrency(result)}
        </Button>
        <Basket count={<div className="fs-6">+</div>} onclick={() => addIzdelie(width, height, material, result)} />
        {basketItogo}
      </ButtonToolbar>
      <ListGroup className="mt-2">
        {spisokMap}
      </ListGroup>
    </>
  )
}