export const spisokVert:any = {
  'Аргос':'15.23',
  'Аризона Blackout':'24.93t',
  'Аруба':'16.52',
  'Бали':'12.61',
  'Бейрут ':'16.34',
  'Бон':'10.11',
  'Венера ':'13.28',
  'Венера люрикс':'15.61',
  'Венера техно':'18.17',
  'Виктория':'14.97',
  'Джангл ':'15.02',
  'Джангл люрикс':'16.47',
  'Джангл металлик':'17.20',
  'Диана':'21.35',
  'Жакард Blackout':'24.18',
  'Жемчуг ':'18.89',
  'Жемчуг Blackout':'26.40',
  'Кёльн':'11.00',
  'Креп':'10.06',
  'Лайн 2':'9.71',
  'Лейла':'20.08',
  'Ливс':'15.23',
  'Лион':'13.34',
  'Маис':'14.97',
  'Мальта':'9.60',
  'Манила':'22.59',
  'Марсель':'14.97',
  'Милан':'12.92',
  'Оптима ':'17.18',
  'Офис Blackout':'21.25',
  'Перл':'17.34',
  'Перл Blackout':'26.59',
  'Плэйн':'9.95',
  'Плейн Blackout':'20.00',
  'Ратан ':'17.62',
  'Рейн':'12.90',
  'Рио':'12.76',
  'Рококо':'15.23',
  'Руан':'13.39',
  'Саванна':'15.23',
  'Сеул':'12.18',
  'Сиде':'13.50',
  'Сиде Blackout':'22.36',
  'Скрин':'32.54',
  'Скрин 2':'27.23',
  'Сфера':'15.23',
  'Сэнди':'22.10',
  'Шелк':'19.53',
  'Шелк 2':'11.58',
  'Шикатан':'25.23',
  'Эйлат':'14.61'
}
























































