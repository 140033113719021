export const texts = [
  "Вертикальные тканевые жалюзи предназначены для защиты от солнечного света, блокируя его попадание в помещение.Ткань, используемая в вертикальных жалюзи, обычно является светозащитным материалом, таким как плотная ткань, которая предназначена для блокирования большей части солнечного света. Жалюзи можно регулировать, вращая стержень или палочку, чтобы открывать и закрывать жалюзи. Затемняющая ткань обычно изготавливается из тяжелого, плотно сплетенного материала, который не пропускает свет в помещение.",
  "Горизонтальные жалюзи представляют собой панели, которые перемещаются по горизонтальной оси и используются для поддержания правильного уровня освещения и внутреннего микроклимата. Эти горизонтальные жалюзи применяются для контроля проникновения света и температуры в комнату. Они могут быть использованы на окнах в домах, офисах, магазинах и других помещениях. Кроме того, в некоторых случаях горизонтальные жалюзи могут быть использованы для улучшения безопасности и контроля доступа.",
  "Рулонные шторы на окна являются идеальным решением для оформления интерьера. Они позволяют добиться стильного и необычного вида. У них есть дополнительные преимущества, такие как простота монтажа, разнообразие дизайна и комплекта для окон, а также стильные и практичные цвета. Они просто устанавливаются на любые окна и дают гармоничное сочетание с другими декоративными элементами, такими как декоративные подушки или карнизы. Они также могут быть использованы для регулировки света и температуры в помещении.",
  "Шторы плиссе в стиле минимализма замечательно подходят для создания чистого и приятного интерьера. Плиссе представляют собой классические полотна 'гармошки', которые прекрасно гармонируют с различными видами интерьеров. Они имеют простое и минималистичное дизайнерское решение, которое подчеркивает стиль вашего интерьера и придает ему уют и легкость. Такие шторы прекрасно подходят для создания ощущения простоты и прозрачности. Они могут добавить необычный эффект в ваш интерьер, делая его более гармоничным и красивым.",
  "Защитные рольставни созданы из профилей различной степени защиты, и их конструкция имеет прочную и надежную структуру. Они изготовлены из металла, алюминия с пенонаполнением или цельнотянутого (экструдированного) алюминия, что делает их прочными и долговечными. Такие рольставни могут быть использованы для защиты от нежелательного входа в помещение, а также для предотвращения попадания посторонних лиц в помещение. Они могут быть использованы для защиты дверей, окон и других входных путей. Защитные рольставни могут быть изготовлены в различных цветах, размерах.",
  "Поднимать и опускать защитные роллеты можно разными способами в зависимости от их веса, размера. Наиболее распространенными являются электромеханические системы, при которых вращение двигателя используется для поднятия и опускания роллет. Такие системы могут быть установлены практически на любой объект. Также используются механические системы, которые представляют собой механизм, предназначенный для поднятия и опускания роллет. Наиболее простые и бюджетные из них — шнуровое управление и управление при помощи пружинно-инерционного механизма. В некоторых случаях используются автоматические системы, которые работают по принципу пульта дистанционного управления. Такие системы могут быть оснащены таймерами и сенсорами для автоматического поднятия и опускания роллет.",
  "У нас есть всё необходимое оборудование, а также опыт и ресурсы, чтобы смонтировать рольставни любой сложности и для любого объекта. Наши специалисты профессионально и качественно проведут установку рольставней, выполняя все процедуры по монтажу без каких-либо проблем. После установки рольставни будут протестированы и проверены на отсутствие скрытых неисправностей. Также мы произведем замену деталей рольставней и предоставим гарантию на ремонт.",
  "рассчитать стоимость роллет (на калькуляторе ▼ произвести расчёт стоимости)",
  "Гаражные секционные ворота - наиболее удобный и практичный способ закрыть проём для въезда автомобиля или подъезда к дому. Они состоят из секций, которые перемещаются по плоскости стены, уходя в плоскость потолка. Эти секции изготовлены из стальных сэндвич-панелей с заполнением вспененным полиуретаном. Они предоставляют отличную защиту предотвращения несанкционированного доступа и обеспечивают безопасность для вашего дома. Они могут быть изготовлены с различными функциями, такими как автоматическое открывание и закрывание, сигнализация и т. д.",
  "Рулонные ворота являются вариантом автоматических ворот, которые позволяют автоматически открываться и закрываться для доступа и защиты. Они имеют множество преимуществ по сравнению с другими видами автоматических ворот, таких как подъемные секционные ворота или складные ворота. Они являются легкими и простыми в установке и обслуживании. Они также более экономичные, чем другие виды автоматических ворот, и предоставляют больше возможностей для защиты и безопасности. Рулонные ворота могут быть использованы для разделения пространства или для предоставления доступа к любому типу помещения.",
  "Откатные ворота являются одним из самых распространенных видов ворот. Они представляют собой полотно, расположенное на балке, внутри которой ролики на подшипниках, закрепленные на платформе, расположенной сбоку от проема. Они могут быть изготовлены из различных материалов, наиболее распространенными из них являются металл, дерево и пластик. Откатные ворота имеют малую стоимость и подходят для различных видов домов и предприятий. Они просты в установке и не требуют много пространства для открывания и закрывания.",
  "Автоматизация для распашных, откатных и секционных ворот, автоматические рольставни и двери - это современные системы, предназначенные для автоматизации таких предметов, как ворота, двери и рольставни. Они позволяют производить открытие и закрытие этих предметов без присутствия человека, что позволяет значительно сократить затраты на работу и увеличить безопасность. Такие системы могут быть оснащены множеством различных функций, таких как автоматическое закрытие, интеллектуальный пульт дистанционного управления и датчики присутствия. Такие системы могут быть установлены и настроены для использования в различных условиях и при работе с различными видами ворот, дверей и рольставен."
]