import { FC, useContext, useEffect, useRef, useState } from "react";
import { Col, Nav, Navbar, Offcanvas, Row } from "react-bootstrap"
import { Hamburger } from "./Hamburger"
import { MenuContact } from "./MenuContact"
import './Header.css'
import { TopMenu } from "./TopMenu";
import { Context } from "../../context";
import { NaGlavnuyu } from "../NaGlavnuyu";
import { Search } from "./Search";
import { ContactsAllInOne } from "./ContactsAllInOne";
import { Basket } from "./Basket";
import { useAppSelector } from "../../UlbiTV/hooks/redux";
import { BrowserRouter, Route, useLocation, useRoutes } from "react-router-dom";
import { links } from "../../links";
import { ShopingCartNav } from "../ShopingCart/components/ShopingCartNav";

interface Props {
  countBasket: number
}

export const Header:FC<Props> = ({countBasket}) => {

  const { takeNum, takeHasMore, setAccordionClickOpen, counts } = useContext<any>(Context)

  // const location = useLocation()
  // const stateCount = location.state.counts

  const ham = document.getElementById('hamburger')

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [openclose, setOpenclose] = useState(false)

  const showTrue = () => {
    if (openclose) {
      handleClose()
      setTimeout(() => setOpenclose(show), 100)
    } else {
      handleShow()
      setTimeout(() => setOpenclose(show), 100)
    }
  }
  //const location = useLocation()
  const {count} = useAppSelector(state=> state.userReducer)

//const pathes = useRoutes(links.map(i=>({i, <Header/>})))

  return (
    <>
    
        <Navbar className="py-1">
          <Nav className="me-auto min820 ms-2">
            <ContactsAllInOne height={38} width={50} />
          </Nav>
          <Nav className="me-auto max820">
            <MenuContact />
          </Nav>
          <Nav className="me-auto">
            <NaGlavnuyu text='GerArt' classnames={"me-auto p-1"} />
          </Nav>
          <Nav className="me-auto max890">
            <Search />
          </Nav>
          {/* <Nav className="me-2">
            <Basket count={count} onclick={undefined} />
          </Nav> */}
          <Nav className="me-4">
            <ShopingCartNav />
          </Nav>
          <Nav id="hamburgerButton" className="me-2 z_index" onClick={() => showTrue()}>
            <Hamburger checked={show} />
          </Nav>
        </Navbar>
        <Offcanvas show={show} onHide={() => { handleClose(); ham?.click(); setTimeout(() => setOpenclose(false), 101) }} placement="end">
          <Offcanvas.Header className="p-0 m-0">
            <Offcanvas.Title>
              <MenuContact />
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <TopMenu onChange={txt => setTimeout(() => setOpenclose(txt), 101)} />
          </Offcanvas.Body>
        </Offcanvas>
      
    </>
  )
}
