import { Container } from "react-bootstrap"
import { Logo } from "../Header/Logo"

export const logIn = 
    <Container className="text-center">

      <main className="form-signin w-50 mx-auto my-2">
        <form>
          <Logo />
          <h1 className="h3 mb-3 fw-normal">Пожалуйста, войдите</h1>

          <div className="form-floating mb-3">
            <input type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
            <label htmlFor="floatingInput">Email адрес</label>
          </div>
          <div className="form-floating mb-3">
            <input type="password" className="form-control" id="floatingPassword" placeholder="Password" />
            <label htmlFor="floatingPassword">Пароль</label>
          </div>

          <div className="checkbox mb-3">
            <label>
              <input type="checkbox" value="remember-me" /> Запомнить меня
            </label>
          </div>
          <button className="w-100 btn btn-lg btn-primary" type="submit">Войти</button>
        </form>
      </main>



    </Container>