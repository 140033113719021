import { FC, useState } from "react";
import { Button, Offcanvas } from "react-bootstrap";
import './OffcanvasBootstrap.css'

interface Props {
  body: any
  variant: any
  textbtn: any
  title: any
  placement: any
}

export const OffcanvasBootstrap: FC<Props> = ({ body, variant, textbtn, title, placement }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Button variant={variant} onClick={handleShow}>
        {textbtn}
      </Button>

      <Offcanvas className="z-index" show={show} onHide={handleClose} placement={placement}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {body}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}