import { Button, ButtonToolbar, Nav } from "react-bootstrap"
import { MasonryUniversal } from "../../../Masonry_universal"
import { ModalBootstrap } from "../../../ModalBootstrap"
import './VertikalInAccordion.css'
import { ReactComponent as Photo } from '../img/photo.svg'
import { ReactComponent as System } from '../img/system.svg'
import { ReactComponent as Material } from '../img/material.svg'
import { ReactComponent as Calc } from '../img/calc.svg'
import { links } from "../../../../links"
import { FC } from "react"
import { useLocation } from "react-router-dom"
import { CalcVertical } from "../../../Calcs/CalcVertikal"
import { OffcanvasBootstrap } from "../../../OffcanvasBootstrap"
import { ContactsAllInOne } from "../../../Header/ContactsAllInOne"

export const VertikalInAccordion: FC = () => {

  let location = useLocation()

  const podrobnee = (location.pathname === '/') ?
    <div className="d-grid gap-2 mt-2">
      <Button
        variant="outline-primary"
        href={links[0]}
      >...подробнее</Button>
    </div> : <></>

  const modal_vert_img = <ModalBootstrap
    title=""
    variant={"outline-primary"}
    btn_txt={
      <>
        <Nav.Item>
          <Photo />
        </Nav.Item>
        <Nav.Item>
          <span className="span_text">фото</span>
        </Nav.Item>
      </>
    }
    children={
      <MasonryUniversal
        id={'vert'}
        path_images={'./vert/'}
        n={15} />
    } />

  const modal_vert_calc = <OffcanvasBootstrap
    title=""
    variant={"outline-primary"}
    textbtn={<>
      <Nav.Item>
        <Calc />
      </Nav.Item>
      <Nav.Item>
        <span className="span_text">расчёт</span>
      </Nav.Item>
    </>}
    body={<CalcVertical />}
    placement='end'
  />

  const contacts = (location.pathname === '/') ? <></> : <ContactsAllInOne />

  return (
    <>
      <ButtonToolbar
        className="justify-content-between"
        aria-label="Toolbar with Button groups">

        {modal_vert_img}

        <Button variant="outline-primary">
          <Nav.Item>
            <System />
          </Nav.Item>
          <Nav.Item>
            <span className="span_text">системы</span>
          </Nav.Item>
        </Button>

        <Button variant="outline-primary">
          <Nav.Item>
            <Material />
          </Nav.Item>
          <Nav.Item>
            <span className="span_text">материал</span>
          </Nav.Item>
        </Button>

        {modal_vert_calc}

        {contacts}

      </ButtonToolbar>



      {podrobnee}

    </>
  )
}