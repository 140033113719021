import { FC } from "react"
import { Dropdown, Nav } from "react-bootstrap"
import { ReactComponent as System } from '../img/contacts.svg'
import { MenuContact } from "../MenuContact"
import './ContactsAllInOne.css'

interface Props {
  span?: any
  width?: number
  height?: number
}

export const ContactsAllInOne: FC<Props> = ({ span = '', width = 30, height = 54 }) => {



  return (
    <>
      <Dropdown>
        <Dropdown.Toggle variant="outline-primary" className="m-0 py-2 ps-1" id="dropdown-contacts">
          <System width={width} height={height} />
          <span className="span_text">{span}</span>
        </Dropdown.Toggle>

        <Dropdown.Menu className="trans tr">
          <MenuContact />
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}